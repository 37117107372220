import {type Tag, tags} from "../../store";

export async function fetchTags() {
    try {
        const response = await fetch(`${import.meta.env.VITE_LINK as string}/tags`);
        if (response.ok) {
            const tagRes = await response.json();
            const tagList: Tag[] = await Promise.all(
                tagRes.map(async (tag: any) => {
                    return {
                        _id: tag._id,
                        name: tag.name
                    };
                })
            );
            tags.set(new Map(tagList.map(tag => [tag._id, tag])));
        } else {
            console.error("Failed to fetch tags");
        }
    } catch (error) {
        console.error("Error fetching tags:", error);
    }
}