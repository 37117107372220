<script lang="ts">
    import { resendValidationEmail } from "./services/userService.js";

    let email: string = ""; // Email passed as a query parameter
    let resendMessage: string = "";
    let isResendSuccess: boolean = false;
    let isAccountConfirmed: boolean = false;

    // Parse query parameters (e.g., email, confirmation status)
    $: (() => {
        const urlParams = new URLSearchParams(window.location.search); // Use window.location.search for query parameters
        email = urlParams.get("email") || "";
        isAccountConfirmed = urlParams.get("confirmed") === "true";
    })();

    async function onResendEmail() {
        if (!email) {
            resendMessage = "Please enter your email address.";
            isResendSuccess = false;
            return;
        }

        const result = await resendValidationEmail(email);
        resendMessage = result.message;
        isResendSuccess = result.success;
    }
</script>

<div class="confirmation-container">
    {#if isAccountConfirmed}
        <!-- Account Confirmed Message -->
        <h2 class="text-center mb-4 text-success">Your account has been confirmed successfully!</h2>
        <p class="text-center">
            You can now <a href="/login" class="text-link">log in</a> to your account.
        </p>
    {:else}
        <!-- Pending Confirmation Message -->
        <h2 class="text-center mb-4">Account Created!</h2>
        <p class="text-center">
            A confirmation link has been sent to <strong>{email}</strong>. Please check your inbox and confirm your account.
            The confirmation link will expire in <strong>1 hour</strong>.
        </p>
        <div class="resend-container">
            <button class="btn btn-secondary resend-btn" on:click={onResendEmail}>
                Resend Confirmation Email
            </button>
            {#if resendMessage}
                <p class="{isResendSuccess ? 'text-success' : 'text-danger'} mt-2">{resendMessage}</p>
            {/if}
        </div>
    {/if}
</div>

<style>
    .confirmation-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100vh;
        background-color: #1a1a1a;
        color: white;
        padding: 2rem;
    }

    .text-success {
        color: green;
    }

    .text-danger {
        color: red;
    }

    .text-link {
        color: #007bff;
        text-decoration: none;
    }

    .text-link:hover {
        text-decoration: underline;
    }

    .resend-btn {
        margin-top: 1rem;
        background-color: #6c757d;
        border: none;
        color: white;
    }

    .resend-btn:hover {
        background-color: #5a6268;
    }
</style>
