import {type Status, statuses} from "../../store";

export async function fetchStatuses() {
    try {
        const response = await fetch(`${import.meta.env.VITE_LINK as string}/statuses`);
        if (response.ok) {
            const statusRes = await response.json();
            const statusList: Status[] = await Promise.all(
                statusRes.map(async (status: any) => {
                    return {
                        _id: status._id,
                        name: status.name
                    };
                })
            );
            statuses.set(new Map(statusList.map(status => [status._id, status])));
        } else {
            console.error("Failed to fetch statuses");
        }
    } catch (error) {
        console.error("Error fetching statuses:", error);
    }
}