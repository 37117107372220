<script lang="ts">
    import {
        categories,
        type Category,
        type CustomCategoryElement,
        customCategoryElements,
        type CustomCategoryName,
        customCategoryNames,
        entries,
        type Entry,
        selectedEntry,
        type Status,
        statuses,
        type Tag,
        tags
    } from "../store";
    import {createEventDispatcher} from "svelte";
    import {format} from "date-fns";
    import {handleMetadataUpdate} from "./services/fetchMetaDataService";
    import {fetchNotes} from "./services/fetchNoteService";

    export let entry: Entry;
    let content = entry.value;
    let url = entry.url;
    let titleExists = entry.title.trim()!=='';

    const dispatch = createEventDispatcher();

    function getCategory(category: string) {
        return $categories.get(category) as Category;
    }

    function getTag(tag: string) {
        return $tags.get(tag) as Tag;
    }

    function getStatus(status: string) {
        return $statuses.get(status) as Status;
    }

    async function updateMetadata(url: string) {
        const updatedMetadata = await handleMetadataUpdate(url, $selectedEntry._id);
        if (updatedMetadata) {
            // Trigger reactivity
            entry.metadata = {
                ...entry.metadata,
                ...updatedMetadata
            };

            entry = { ...entry };
        }
    }

    let customCategoryMap = new Map();
    let temp = Array.from(customCategoryMap.keys());
    $: if (entry) {
        customCategoryMap = new Map();
        entry.customCategories.forEach((element) => {
            const elementObj = $customCategoryElements.get(element) as CustomCategoryElement;
            if (!customCategoryMap.get(elementObj.parentId)) {
                customCategoryMap.set(elementObj.parentId, [elementObj]);
            } else {
                customCategoryMap.get(elementObj.parentId).push(elementObj);
            }
        });
        temp = Array.from(customCategoryMap.keys());
    }

    function getParent(parentId: string) {
        return $customCategoryNames.get(parentId) as CustomCategoryName;
    }

    function getElements(id: string) {
        return customCategoryMap.get(id) as CustomCategoryElement[];
    }
</script>

<div class="modal-header">
    <div class="d-flex justify-content-between align-items-center">
        {#if titleExists}
            <a href="{url}" target="_blank" rel="noopener noreferrer" class="text-decoration-none"  on:click={() => updateMetadata(url)}>
                <h5 class="modal-title fw-bold">{entry.title}</h5>
            </a>
        {/if}
    </div>
    <div>
        <button class="modal-button" on:click={() => dispatch('edit')}>
            <svg width="24" height="20">
                <use href="#icon-edit-simple"/>
            </svg>
        </button>
        <button class="modal-button" on:click={() => dispatch('delete')} data-testid="delete-button">
            <svg width="24" height="20">
                <use href="#icon-trash"/>
            </svg>
        </button>
        <button class="modal-button" on:click={() => dispatch('close')}>
            <svg width="24" height="20">
                <use href="#icon-close"/>
            </svg>
        </button>
    </div>
</div>

<div class="modal-body d-flex flex-column h-100 position-relative mt-3">
    <p>{entry.value}</p>
    <div class="bookmark-preview">
        <div class="ratio ratio-16x9">
            <a href="{url}" target="_blank" rel="noopener noreferrer" on:click={() => updateMetadata(url)}>
                {#key entry.metadata.image}
                    <img
                        src="{!entry.metadata.image || entry.metadata.image.trim() === ''
                            ? '/loading.jpg'
                            : entry.metadata.image.startsWith('data:image/')
                                ? entry.metadata.image // Use base64-encoded image directly
                                : `${import.meta.env.VITE_LINK}/proxy-image?url=${encodeURIComponent(entry.metadata.image)}&cacheBuster=${Date.now()}`}"
                        alt="preview"
                        class="w-100 h-100 object-fit-cover rounded shadow"
                    />
                {/key}
            </a>
        </div>
        <p>{entry.metadata.description}</p>
    </div>
</div>

<div class="d-flex-row w-100 p-3">
    <!-- Categories -->
    {#if entry.categories.length > 0}
        <div class="d-flex mb-1">
            <div class="types d-inline-flex align-items-center rounded flex-wrap">
                <div class="title fw-bold">
                    Categories:
                </div>
                {#each entry.categories as category}
                    <div class="chip px-3 py-2 rounded d-flex align-items-center text-white fw-bold">
                        {getCategory(category).name}
                    </div>
                {/each}
            </div>
        </div>
    {/if}

    <!-- Tags -->
    {#if entry.tags.length > 0}
        <div class="d-flex mb-1">
            <div class="types d-inline-flex align-items-center rounded flex-wrap">
                <div class="title fw-bold">
                    Tags:
                </div>
                {#each entry.tags as tag}
                    <div class="chip px-3 py-2 rounded d-flex align-items-center text-white fw-bold">
                        {getTag(tag).name}
                    </div>
                {/each}
            </div>
        </div>
    {/if}

    <!-- Statuses -->
    {#if entry.statuses.length > 0}
        <div class="d-flex mb-1">
            <div class="types d-inline-flex align-items-center rounded flex-wrap">
                <div class="title fw-bold">
                    Statuses:
                </div>
                {#each entry.statuses as status}
                    <div class="chip px-3 py-2 rounded d-flex align-items-center text-white fw-bold">
                        {getStatus(status).name}
                    </div>
                {/each}
            </div>
        </div>
    {/if}

    {#if temp.length > 0}
        {#each temp as parent}
            <div class="d-flex mb-1">
                <div class="types d-inline-flex align-items-center rounded flex-wrap">
                    <div class="title fw-bold">
                        {getParent(parent).name}:
                    </div>
                    {#each getElements(parent) as element}
                        <div class="chip px-3 py-2 rounded d-flex align-items-center text-white fw-bold">
                            {element.name}
                        </div>
                    {/each}
                </div>
            </div>
        {/each}
    {/if}

    <!-- Date -->
    <div class="d-flex mb-1">
        <div class="types d-inline-flex align-items-center rounded flex-wrap">
            <p class="title fw-bold">{format(entry.timestamp, "MMM d, yyyy")}</p>
        </div>
    </div>
</div>


<style>
    .modal-header {
        display: flex;
        align-items: center;
    }

    .modal-title {
        margin: 0;
    }

    .modal-title:hover {
        text-decoration: underline;
    }

    .modal-button {
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        transition: transform 0.05s ease-in-out;
    }

    .modal-button:hover {
        transform: scale(1.1);
    }

    .title {
        gap: 0.15rem;
    }

    .types {
        background-color: #d4dae5;
        margin-bottom: 0.5rem;
        padding: 0.15rem 0.25rem;
        margin-left: 0.5rem;
    }

    .chip {
        margin-left: 0.25rem;
        height: 2rem;
        background-color: #6d7583;
    }

    .text-border {
        padding: 0.375rem 0.75rem;
        box-sizing: border-box;
        transition: box-shadow 0.2s ease-in-out;
    }

    .modal-footer {
        border-bottom: none;
        border-top: none;
    }

    .text-border:focus {
        box-shadow: 0 0 0 0.2rem rgba(79, 141, 255, 0.34);
        outline: none;
    }

    .bookmark-preview {
        max-width: 600px;
    }

    .bookmark-preview img {
        max-width: 600px;
    }
</style>