<script lang="ts">
    import { navigate } from "svelte-routing";

    let email = '';
    let showSuccessMessage = false;
    let errorMessage = '';

    async function onFormSubmit(event: SubmitEvent) {
        event.preventDefault();
        errorMessage = '';

        const result = await sendPasswordResetRequest(email.trim().toLowerCase());
        if (result.success) {
            showSuccessMessage = true;
        } else {
            errorMessage = result.message || 'An unknown error occurred.';
        }
    }

    async function sendPasswordResetRequest(email: string): Promise<{ success: boolean; message?: string }> {
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                return { success: true };
            } else {
                const data = await response.json();
                return { success: false, message: data.message || 'Something went wrong. Please try again.' };
            }
        } catch (error) {
            return { success: false, message: 'Unable to connect to the server. Please try again later.' };
        }
    }

    function goToLogin() {
        navigate('/login');
    }
</script>

<div class="container-fluid vh-100">
    <div class="row h-100">
        <div class="col-md-4 d-flex justify-content-center align-items-center bg-dark text-white">
            <div class="w-75">
                <h2 class="mb-4 text-center">Forgot Password</h2>
                {#if showSuccessMessage}
                    <p class="text-center mb-4">An email has been sent to your address with instructions to reset your password.</p>
                    <button class="btn btn-primary w-100 mb-3" on:click={goToLogin}>Back to Login</button>
                {:else}
                    <form on:submit|preventDefault={onFormSubmit}>
                        <div class="mb-3">
                            <label for="email" class="form-label text-white">Email</label>
                            <input
                                    type="email"
                                    class="form-control bg-dark text-white border-secondary"
                                    id="email"
                                    bind:value={email}
                                    placeholder="Enter your email"
                                    required
                            />
                        </div>
                        {#if errorMessage}
                            <p class="text-danger mb-3">{errorMessage}</p>
                        {/if}
                        <button type="submit" class="btn btn-primary w-100">Send Reset Email</button>
                    </form>
                    <div class="mt-3 text-center">
                        <a href="#" class="text-decoration-none text-white" on:click={goToLogin}>Back to Login</a>
                    </div>
                {/if}
            </div>
        </div>
        <div class="col-md-8 d-none d-md-block p-0 position-relative">
            <div class="bg-image">
                <div class="image-content text-white text-center">
                    <h1 class="display-4">Timeline</h1>
                    <p class="fs-3">Knowledge management and personal archive</p>
                    <p class="h6 lead">
                        Organize your notes and bookmarks with ease and see them come to life on a timeline...
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<style>
    .bg-image {
        position: relative;
        width: 100%;
        height: 100%;
        background-image: url('https://images.unsplash.com/photo-1579548122080-c35fd6820ecb?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .bg-image::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .image-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        padding: 0 2rem;
    }

    .col-md-8 {
        position: relative;
        overflow: hidden;
    }

    .form-control {
        background-color: #2c2c2c;
        border-color: #555;
        color: #fff;
    }

    .form-control::placeholder {
        color: #bbb;
    }

    .text-danger {
        color: #dc3545 !important;
    }
</style>
