<script lang="ts">
    import {selectedCategories, categories, categoryTree, type Category} from "../store";
    import CategoryTree from "./CategoryTree.svelte";
    import {addCategory} from "./services/addCategoryService";
    import {fetchCategories} from "./services/fetchCategoriesService";
    import {buildCategoryTree} from "./services/buildCategoryTreeService";

    async function handleFetchCategories() {
        await fetchCategories();
        categoryTree.set(buildCategoryTree($categories));
    }

    function toggleSelectCategory(categoryId: string) {
        selectedCategories.update(currentCategories => {
            // If the category is already selected, remove it
            if (currentCategories.includes(categoryId)) {
                return currentCategories.filter(selected => selected !== categoryId);
            } else {
                // Else we add it to the selected list
                return [...currentCategories, categoryId];
            }
        });
    }

    let isAdding = false;
    let newCategoryName = '';

    async function handleAddCategory(event: Event, newCategoryName:string, parentId: string) {
        event.preventDefault();
        try {
            await addCategory(newCategoryName, parentId);
            isAdding = false;
            await handleFetchCategories()
        } catch (error) {
            console.error(error);
        }
        newCategoryName = '';
    }

    function openTextField() {
        isAdding = true;
    }
</script>

<div class="comp bg-dark">
    <div class="sidebar bg-dark text-white d-flex flex-column">

        <div class="h3 mb-2 d-flex justify-content-between">
            Category
            <div class="action-buttons">
                <button on:click|stopPropagation={() => openTextField()}>+</button>
            </div>
        </div>

        {#if isAdding}
            <form on:submit={(event) => {handleAddCategory(event, newCategoryName, ''); newCategoryName = ''}}>
                <input bind:value={newCategoryName} placeholder="Category name" required/>
                <button type="submit" class="btn btn-primary">Save</button>
                <button type="button" class="btn btn-secondary"
                        on:click={() => { isAdding = false; newCategoryName = ''; }}>Cancel
                </button>
            </form>
        {/if}

        {#each $categoryTree as rootCategory}
            <CategoryTree category={rootCategory} />
        {/each}
    </div>
</div>


<style>
    .comp {
        overflow: auto;
    }

    .sidebar {
        /*box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);*/
        padding-top: 1rem;
        padding-left: 1rem;
        padding-right: 0;
    }

    .action-buttons button {
        display: inline-block;
        background: none;
        border: none;
        color: #fff; /* Icon color */
        font-size: 16px;
        margin-right: 8px;
        cursor: pointer;
    }
</style>
