<script lang="ts">
    import {
        type CustomCategoryElement, customCategoryElements, customCategoryMap, type CustomCategoryName,
        customCategoryNames, selectedCustomCategories,
        selectedCustomCategoryBarIds,
        uiTopSectionHeight
    } from "../store";
    import {onMount} from "svelte";
    import CustomCategory from "./CustomCategoryElement.svelte";
    import {fetchCustomCategoryNames} from "./services/fetchCustomCategoryNamesService";
    import {buildCustomCategories} from "./services/buildCustomCategoriesService";
    import {fetchCustomCategoryElements} from "./services/fetchCustomCategoryElementsService";
    import {fetchNotes} from "./services/fetchNoteService";
    import {entries} from "../store.js";

    export let id: number;

    let halfTopSection: HTMLElement;
    let dropdownRef: HTMLElement;
    let inputRef: HTMLInputElement | null = null;
    let halfHeight = 0;
    let componentHeight = 0;
    let isDropdownOpen = false;
    let isAdding = false;
    let addingNewCustomCategory = false;
    let editingCustomCategoryId = '';
    let editingCustomCategoryName = '';
    let newCustomCategoryName = '';
    let newCustomCategoryElement = '';
    let emptyDropDownTextFiller = 'Custom Category';
    let dropdownText = emptyDropDownTextFiller;
    let customElements: CustomCategoryElement[] = [];

    async function fetchCustomCategoryElementsByParentId(parentId: string) {
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/customcategoryelements/parent/${parentId}`);
            if (response.ok) {
                const customRes = await response.json();
                return await Promise.all(
                    customRes.map(async (element: any) => {
                        return {
                            _id: element._id,
                            name: element.name,
                            parentId: element.parentId
                        };
                    })
                );
            } else {
                console.error("Failed to fetch CustomCategoryElements");
                return [];
            }
        } catch (error) {
            console.error("Error fetching CustomCategoryElements:", error);
            return [];
        }
    }

    async function addNewCustomCategory() {
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/customcategorynames`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({name: newCustomCategoryName})
            });

            newCustomCategoryName = '';
            if (!response.ok) {
                throw new Error('Failed to add new custom category');
            }
            await fetchCustomCategoryNames();
            buildCustomCategories();
        } catch (error) {
            console.error(error);
        }
    }

    async function handleAddCustomCategoryElement(event: Event, parentId: string) {
        event.preventDefault();
        isAdding = false;
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/customcategoryelements`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({name: newCustomCategoryElement, parentId: parentId})
            });
            if (!response.ok) {
                throw new Error('Failed to add new element');
            }
        } catch (error) {
            console.error(error);
        }
        await fetchCustomCategoryElements();
        newCustomCategoryElement = '';
        const temp: CustomCategoryElement[] = await fetchCustomCategoryElementsByParentId(parentId);
        $customCategoryMap.set(parentId, temp);
        customElements = $customCategoryMap.get(parentId) as CustomCategoryElement[];
    }

    async function editCustomCategoryName(oldName: string) {
        try {
            if (oldName !== editingCustomCategoryName) {
                let name = editingCustomCategoryName;
                const response = await fetch(`${import.meta.env.VITE_LINK as string}/customcategorynames/${editingCustomCategoryId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({name})
                });
                if (!response.ok) {
                    throw new Error('Failed to update custom category name');
                }
                await fetchCustomCategoryNames();
                if ($selectedCustomCategoryBarIds[id] === editingCustomCategoryId) {
                    dropdownText = ($customCategoryNames.get(editingCustomCategoryId) as CustomCategoryName).name
                }
                editingCustomCategoryName = '';
                editingCustomCategoryId = '';
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function deleteCustomCategory(catId: string, catName: string) {
        selectedCustomCategories.set($selectedCustomCategories.filter(([_, parId]) => parId !== catId));
        if ($selectedCustomCategoryBarIds[0] === catId) {
            $selectedCustomCategoryBarIds[0] = '';
        } else if ($selectedCustomCategoryBarIds[1] === catId) {
            $selectedCustomCategoryBarIds[1] = '';
        }
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/customcategorynames/${catId}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error('Failed to delete custom category');
            }
            dropdownText = emptyDropDownTextFiller;
            await fetchCustomCategoryNames()
            await fetchCustomCategoryElements();
            buildCustomCategories();
        } catch (error) {
            console.error("Error deleting custom category:", error);
        }
    }

    async function deleteCustomCategoryElement(elementId: string, parentId: string) {
        selectedCustomCategories.set($selectedCustomCategories.filter(([elId, _]) => elId !== elementId));
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/customcategoryelements/${elementId}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error('Failed to delete custom category element');
            }
            const temp: CustomCategoryElement[] = await fetchCustomCategoryElementsByParentId(parentId);
            $customCategoryMap.set(parentId, temp);
            customElements = $customCategoryMap.get(parentId) as CustomCategoryElement[];
            entries.set(await fetchNotes());
        } catch (error) {
            console.error("Error deleting custom element:", error);
        }
    }

    async function editCustomCategoryElementById(elementId: string, parentId: string, name:string, oldName: string) {
        try {
            if (name !== oldName) {
                const response = await fetch(`${import.meta.env.VITE_LINK as string}/customcategoryelements/${elementId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({name})
                });
                if (!response.ok) {
                    throw new Error('Failed to update the element');
                }
                const temp: CustomCategoryElement[] = await fetchCustomCategoryElementsByParentId(parentId);
                $customCategoryMap.set(parentId, temp);
                customElements = $customCategoryMap.get(parentId) as CustomCategoryElement[];
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function handleSelection() {
        if ($selectedCustomCategoryBarIds[id] !== '') {
            customElements = $customCategoryMap.get($selectedCustomCategoryBarIds[id]) as CustomCategoryElement[];
        }
    }

    $: if (inputRef) {
        inputRef.focus();
    }

    function handleClickOutside(event: MouseEvent) {
        if (dropdownRef && !dropdownRef.contains(event.target as Node)) {
            isDropdownOpen = false;
        }
    }

    function updateHeight() {
        const windowHeight = window.innerHeight;
        let temp1 = 0;
        let temp2 = 0;
        if (windowHeight) {
            temp1 = ($uiTopSectionHeight / windowHeight) * 100;
            temp2 = (halfTopSection.clientHeight / windowHeight) * 100;
            halfHeight = (100 - temp1) / 2 - 2 * ((16 / windowHeight) * 100);
            componentHeight = halfHeight - 1.1 * temp2
        }
    }

    let selectedId = ''
    $: if (selectedCustomCategoryBarIds) {
        if ($selectedCustomCategoryBarIds[id] !== '') {
            dropdownText = ($customCategoryNames.get($selectedCustomCategoryBarIds[id]) as CustomCategoryName).name
            selectedId = $selectedCustomCategoryBarIds[id];
            customElements = $customCategoryMap.get($selectedCustomCategoryBarIds[id]) as CustomCategoryElement[];
        } else {
            dropdownText = emptyDropDownTextFiller;
            selectedId = '';
            customElements = [];
        }
    }

    onMount(() => {
        updateHeight();
        window.addEventListener('resize', updateHeight);
        document.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    })
</script>

<div style={`height: ${halfHeight}vh;`}>
    <div bind:this={halfTopSection}>
        <div class="d-flex align-items-center">
            <div class="d-flex-column" bind:this={dropdownRef}>
                <button class="dropdown-header" on:click={() => isDropdownOpen = !isDropdownOpen}>
                    {dropdownText} ▼
                </button>

                {#if isDropdownOpen}
                    <ul class="custom-dropdown-menu rounded">
                        {#each $customCategoryNames as [key, custom]}
                            {#if editingCustomCategoryId !== custom._id}
                                <li class="element">
                                    <div class="d-flex justify-content-between">
                                        <button class="dropdown-button" on:click={() => {
                                            dropdownText = custom.name;
                                            isDropdownOpen = false;
                                            $selectedCustomCategoryBarIds[id]=custom._id;
                                            if ($selectedCustomCategoryBarIds[(id+1)%2] === custom._id) {
                                                $selectedCustomCategoryBarIds[(id+1)%2] = '';
                                            }
                                            handleSelection();
                                        }}>
                                            {custom.name}
                                        </button>
                                        <div class="action-buttons d-flex justify-content-between">
                                            <button class="category_buttons" on:click|stopPropagation={() => {
                                                    editingCustomCategoryId = custom._id; editingCustomCategoryName = custom.name;
                                                }}>
                                                <svg width="16" height="16">
                                                    <use href="#icon-edit-simple"></use>
                                                </svg>
                                            </button>
                                            <button class="category_buttons"
                                                    on:click|stopPropagation={() => deleteCustomCategory(custom._id, custom.name)}>
                                                <svg width="16" height="16">
                                                    <use href="#icon-trash"></use>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            {:else}
                                <input
                                        type="text"
                                        bind:value={editingCustomCategoryName}
                                        bind:this={inputRef}
                                        class="editable"
                                        on:blur={() => {editingCustomCategoryId = ''; editingCustomCategoryName = '';}}
                                        on:keyup|stopPropagation={(e) => {
                                if (e.key === 'Enter') {
                                    editCustomCategoryName(custom.name);
                                } else if (e.key === 'Escape') {
                                    editingCustomCategoryId = '';
                                    editingCustomCategoryName = '';
                                }
                            }}
                                />
                            {/if}
                        {/each}
                        {#if !addingNewCustomCategory}
                            <li class="plus-button-container">
                                <button class="plus-button" on:click|stopPropagation={() => addingNewCustomCategory = true}>
                                    +
                                </button>
                            </li>
                        {:else}
                            <li>
                                <input
                                        type="text"
                                        bind:value={newCustomCategoryName}
                                        bind:this={inputRef}
                                        class="editable"
                                        on:blur={() => addingNewCustomCategory=false}
                                        on:keyup={(e) => {
                                if (e.key === 'Enter') {
                                    addNewCustomCategory()
                                    addingNewCustomCategory = false;
                                } else if (e.key === 'Escape') {
                                    addingNewCustomCategory = false;
                                }
                            }}
                                />
                            </li>
                        {/if}

                    </ul>
                {/if}
            </div>

            {#if $selectedCustomCategoryBarIds[id] !== ''}
                <div class="action-buttons2">
                    <button on:click|stopPropagation={() => {
                                isAdding = true; newCustomCategoryElement = '';
                            }}>+
                    </button>
                </div>
            {/if}
        </div>
        {#if isAdding}
            <form on:submit={(event) => handleAddCustomCategoryElement(event, $selectedCustomCategoryBarIds[id])}>
                <input bind:value={newCustomCategoryElement} placeholder="Name of the new element" required/>
                <button type="submit" class="btn btn-primary">Save</button>
                <button type="button" class="btn btn-secondary"
                        on:click={() => { isAdding = false; newCustomCategoryElement = ''; }}>Cancel
                </button>
            </form>
        {/if}
    </div>

    <div>
        {#if selectedId !== ''}
            <CustomCategory parentId={selectedId}
                            customCategoryElements={customElements}
                            height={`height: ${componentHeight}vh;`}
                            on:edit={(event) => {editCustomCategoryElementById(event.detail.elementId, event.detail.parentId, event.detail.newName, event.detail.oldName)}}
                            on:delete={(event) => {deleteCustomCategoryElement(event.detail.elementId, event.detail.parentId)}}
            />
        {/if}
    </div>
</div>

<style>
    .plus-button-container {
        display: flex; /* Use flex to center the button */
        justify-content: center; /* Center horizontally */
    }

    .plus-button {
        background: transparent;
        color: white;
        font-size: 24px; /* Larger font size for the "+" */
        border: none;
        border-radius: 50%;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .action-buttons2 button {
        display: inline-block;
        background: none;
        border: none;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        padding-left: 1rem;
    }

    .dropdown-button {
        background-color: transparent; /* No background */
        border: none; /* No border */
        padding: 8px 12px; /* Padding for the button */
        color: white; /* Text color */
        font-size: 14px; /* Adjust text size */
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
        width: 100%; /* Make the button take full width inside the container */
        text-align: left; /* Align text to the left */
    }

    /* Active state for the dropdown button */
    .dropdown-button:active {
        background-color: #ccc; /* Slightly darker background when active */
    }

    .dropdown-header {
        all: unset;
        display: flex;
        gap: 2rem;
        padding: 0.5rem 1rem;
        background-color: #2c313a; /* Dark background */
        border: 2px solid #343a40; /* Slight border */
        border-radius: 5px; /* Rounded corners */
        color: #ffffff;
        cursor: pointer;
        font-size: 18px; /* Adjust font size as needed */
    }

    .action-buttons {
        display: inline-flex;
        margin-left: auto; /* Push icons to the right side */
    }

    .action-buttons button {
        display: inline-block;
        background: none;
        border: none;
        color: #fff; /* Icon color */
        font-size: 16px;
        margin-right: 8px;
        cursor: pointer;
    }

    .action-buttons button:hover {
        color: #fff; /* Highlight icon on hover */
    }

    /* Hide action buttons by default */
    .action-buttons {
        visibility: hidden;
    }

    /* Show buttons on hover */
    .element:hover .action-buttons {
        visibility: visible;
    }

    .custom-dropdown-menu {
        position: absolute;
        margin-top: 1px;
        background-color: #343a40;
        border-radius: 2px;
        padding: 10px;
        list-style: none;
        z-index: 1001;
        max-height: 25vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .select-wrapper {
        position: relative;
        width: 100%;
        margin-right: 10px;
    }

    select {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        border-radius: 5px;
        border: 1px solid #ccc;
        background-color: #212529;
        color: #fff;
        appearance: none;
        cursor: pointer;
        transition: border-color 0.3s;
    }

    select:focus {
        border-color: #007bff;
        outline: none;
    }

    .select-wrapper::after {
        content: '▼';
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        color: #fff;
        pointer-events: none;
    }

    .action-buttons button {
        display: inline-block;
        background: none;
        border: none;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
    }

    .action-buttons button:hover {
        color: #007bff;
    }

    .action-buttons {
        display: flex;
        align-items: center;
    }

    form {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }

    form input {
        padding: 10px;
        margin-right: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
        background-color: #212529;
        color: #fff;
    }

    form input:focus {
        border-color: #007bff;
        outline: none;
    }

    .btn {
        padding: 10px 15px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
    }

    .btn-primary {
        background-color: #007bff;
        color: white;
        border: none;
    }

    .btn-primary:hover {
        background-color: #0056b3;
    }

    .btn-secondary {
        background-color: #6c757d;
        color: white;
        border: none;
    }

    .btn-secondary:hover {
        background-color: #5a6268;
    }
</style>