import {type CustomCategoryElement, customCategoryElements, customCategoryMap, customCategoryNames} from "../../store";
import {get} from "svelte/store";

export function buildCustomCategories() {
    const map: Map<string, CustomCategoryElement[]> = new Map();
    const elements = get(customCategoryElements)

    Array.from(get(customCategoryNames).keys()).forEach(value => {
        map.set(value, [])
    })

    elements.forEach((value, key) => {
        map.get(value.parentId)!.push(value);
    });

    customCategoryMap.set(map);
}