import {derived, writable} from 'svelte/store';
import mongoose from "mongoose";

export interface Entry {
    _id: string;
    title: string;
    value: string;
    url: string;
    contentType: string;
    categories: string[];
    statuses: string[];
    tags: string[];
    customCategories: string[];
    timestamp: Date;
    metadata: MetaData;
}

export interface Category {
    _id: string;
    name: string;
    parentId: string;
    children: string[];
}

export interface Tag {
    _id: string;
    name: string;
}

export interface Status {
    _id: string;
    name: string;
}

export interface CustomCategoryName {
    _id: string;
    name: string;
}

export interface CustomCategoryElement {
    _id: string;
    name: string;
    parentId: string;
}

export interface MetaData {
    title: string;
    description: string;
    image: string;
    url: string;
}

export const entries = writable<Entry[]>([])
export const isTagDropdownOpen = writable(false);
export const isStatusDropdownOpen = writable(false);
export const selectedEntry = writable<Entry>();
export const showModal = writable(false);
export const selectedCategories = writable<string[]>([]);
export const selectedTags = writable<string[]>([]);
export const selectedStatuses = writable<string[]>([]);
export const selectedStartDate = writable(new Date(-8640000000000000));
export const selectedEndDate = writable(new Date(8640000000000000));
export const oldestDate = writable<Date>();
export const newestDate = writable<Date>();
export const oldestFetchedDate = writable<Date>();
export const newestFetchedDate = writable<Date>();
export const isEditingModal = writable(false);

export const showEntryForm = writable(false);
export const selectedCustomCategories = writable<string[][]>([]);
export const selectedCustomCategoryBarIds = writable<string[]>(['', ''])


export const categories = writable<Map<string, Category>>(new Map());
export const categoryTree = writable<Category[]>([]);
export const tags = writable<Map<string, Tag>>(new Map());
export const statuses = writable<Map<string, Status>>(new Map());
export const customCategoryNames = writable<Map<string, CustomCategoryName>>(new Map());
export const customCategoryElements = writable<Map<string, CustomCategoryElement>>(new Map());
export const customCategoryMap = writable<Map<string, CustomCategoryElement[]>>(new Map())


// Create a derived store that depends on the four stores
export const combinedStore = derived(
    [selectedCategories, selectedTags, selectedStatuses, selectedCustomCategories, selectedStartDate, selectedEndDate],
    ([$selectedCategories, $selectedTags, $selectedStatuses, $selectedCustomCategories, $selectedStartDate, $selectedEndDate]) => {
        return { $selectedCategories, $selectedTags, $selectedStatuses, $selectedCustomCategories, $selectedStartDate, $selectedEndDate};
    }
);

export const entryFormSearchDropDownMenuOpen = writable(false);
export const entryFormSelectedCategoriesIds = writable<string[]>([]);
export const entryFormSelectedTagsIds = writable<string[]>([]);
export const entryFormSelectedStatusesIds = writable<string[]>([]);
export const entryFormInputParameterText = writable<string>('');
export const entryFormCategorySearchMatchIds = writable<Set<string>>(new Set());
export const entryFormCategorySearchDropdownOpen = writable(false);
export const entryFormCategorySearchQuery = writable('');
export const entryFormCategorySearchInputElement = writable<HTMLElement>(undefined);
export const newTagsFromEntryFormTagSearch = writable<string[]>([]);
export const newStatusesFromEntryFormStatusSearch = writable<string[]>([]);
export const newCustomCategoryNamesFromEntryFormAttributeSearch = writable<string[]>([]);
export const newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch = writable<string[][]>([]);
export const newCustomCategoryElementsWithExistingParentFromEntryFormAttributeSearch = writable<string[][]>([]);

export const uiTopSectionHeight = writable(0);
export const uiTimebarHeight = writable(0);

export const rowsCustomCat = writable([{ id: 0, filled: false, parentId: '', elementId: '', newPar: false, newEl: false }])

export function resetStores() {
    entries.set([]);
    isTagDropdownOpen.set(false);
    isStatusDropdownOpen.set(false);
    //selectedEntry.set(undefined);
    showModal.set(false);
    selectedCategories.set([]);
    selectedTags.set([]);
    selectedStatuses.set([]);
    categories.set(new Map());
    categoryTree.set([]);
    selectedStartDate.set(new Date(-8640000000000000));
    selectedEndDate.set(new Date(8640000000000000));
    oldestDate.set(new Date());
    newestDate.set(new Date());
    oldestFetchedDate.set(new Date());
    newestFetchedDate.set(new Date());
    isEditingModal.set(false);
    tags.set(new Map());
    statuses.set(new Map());
    entryFormSearchDropDownMenuOpen.set(false);
    entryFormSelectedCategoriesIds.set([]);
    entryFormSelectedTagsIds.set([]);
    entryFormSelectedStatusesIds.set([]);
    entryFormInputParameterText.set('');
    entryFormCategorySearchMatchIds.set(new Set());
    entryFormCategorySearchDropdownOpen.set(false);
    entryFormCategorySearchQuery.set('');
    newTagsFromEntryFormTagSearch.set([]);
    newStatusesFromEntryFormStatusSearch.set([]);
    newCustomCategoryNamesFromEntryFormAttributeSearch.set([]);
    newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch.set([]);
    newCustomCategoryElementsWithExistingParentFromEntryFormAttributeSearch.set([]);
    uiTopSectionHeight.set(0);
    uiTimebarHeight.set(0);
    customCategoryNames.set(new Map());
    customCategoryElements.set(new Map());
    customCategoryMap.set(new Map())
    selectedCustomCategories.set([]);
    selectedCustomCategoryBarIds.set(['', '']);
    rowsCustomCat.set([{ id: 0, filled: false, parentId: '', elementId: '', newPar: false, newEl: false }]);
}