<script lang="ts">
    import CustomCategorySidebarSection from "./CustomCategorySidebarSection.svelte";
</script>

<div class="comp bg-dark d-flex-column text-white">
    <CustomCategorySidebarSection id={0}/>

    <CustomCategorySidebarSection id={1}/>
</div>

<style>
    .comp {
        padding-left: 1rem;
        padding-top: 1rem;
    }
</style>
