import {
    selectedCategories,
    selectedEndDate,
    selectedStartDate,
    selectedStatuses,
    selectedTags,
    type Entry, selectedCustomCategories, categories
} from "../../store";
import {get} from "svelte/store";

// Function to fetch data based on ObjectId
export async function fetchById(url: string, id: string) {
    const response = await fetch(`${url}/${id}`);
    if (response.ok) {
        const data = await response.json();
        return data.name; // Assuming the 'name' field is returned
    } else {
        console.error(`Failed to fetch data from ${url}/${id}`);
        return '';
    }
}

export async function fetchNotes(): Promise<Entry[]> {
    let entries: Entry[] = [];

    const allIds = new Set(get(selectedCategories));

    let addedNewIds;
    do {
        addedNewIds = false;

        // Loop through the Map to find child elements
        for (const [key, value] of get(categories).entries()) {
            // Check if the parentId exists in the Set
            if (allIds.has(value.parentId) && !allIds.has(key)) {
                allIds.add(key); // Add the child ID
                addedNewIds = true; // Flag to continue the loop
            }
        }
    } while (addedNewIds); // Repeat until no new IDs are added

    try {
        const response = await fetch(`${import.meta.env.VITE_LINK as string}/notes/filters`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                categories: Array.from(allIds),
                statuses: get(selectedStatuses),
                tags: get(selectedTags),
                customCategories: get(selectedCustomCategories),
                startTimestamp: get(selectedStartDate),
                endTimestamp: get(selectedEndDate)
            })
        });
        if (response.ok) {
            const notes = await response.json();

            return (await Promise.all(
                notes.map(async (note: any) => {
                    return {
                        _id: note._id,
                        title: note.title,
                        value: note.content,
                        url: note.url,
                        contentType: note.contentType,
                        categories: note.categories,
                        statuses: note.statuses,
                        tags: note.tags,
                        customCategories: note.customCategories,
                        timestamp: new Date(note.timestamp),
                        metadata: note.metadata
                    };
                })
            ));

        } else {
            console.error("Failed to fetch notes");
        }
    } catch (error) {
        console.error("Error fetching notes:", error);
    }
    return entries;
}
