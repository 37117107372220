<script lang="ts">
    import {
        type CustomCategoryElement,
        customCategoryElements, customCategoryMap, customCategoryNames,
        entries,
        type Entry,
        entryFormSelectedCategoriesIds,
        entryFormSelectedStatusesIds,
        entryFormSelectedTagsIds,
        newCustomCategoryElementsWithExistingParentFromEntryFormAttributeSearch,
        newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch,
        newCustomCategoryNamesFromEntryFormAttributeSearch,
        newStatusesFromEntryFormStatusSearch,
        newTagsFromEntryFormTagSearch,
        rowsCustomCat,
        selectedEntry
    } from "../store";
    import {createEventDispatcher} from "svelte";
    import EntryFormAtributeSearch from "./EntryFormAttributeSearch.svelte";
    import EntryFormStatusSearch from "./EntryFormStatusSearch.svelte";
    import EntryFormCateorySearch from "./EntryFormCateorySearch.svelte";
    import EntryFormTagStatusSearch from "./EntryFormTagSearch.svelte";
    import {fetchTags} from "./services/fetchTagsService";
    import {fetchStatuses} from "./services/fetchStatusesService";
    import {fetchCustomCategoryNames} from "./services/fetchCustomCategoryNamesService";
    import {fetchCustomCategoryElements} from "./services/fetchCustomCategoryElementsService";
    import {buildCustomCategories} from "./services/buildCustomCategoriesService";

    export let entry: Entry;

    const dispatch = createEventDispatcher();

    let entryTitle: string = entry.title;
    let entryValue: string = entry.value;

    async function submitEdit() {
        const title = entryTitle;
        const content = entryValue;
        const categories = $entryFormSelectedCategoriesIds || [];
        const statuses = $entryFormSelectedStatusesIds || [];
        const tags = $entryFormSelectedTagsIds || [];
        const customCategories = $rowsCustomCat.filter(row => row.elementId !== '' && !(row.newPar || row.newEl)).map(row => row.elementId);

        const note = {
            title,
            content,
            categories,
            statuses,
            newStatuses: $newStatusesFromEntryFormStatusSearch,
            tags,
            newTags: $newTagsFromEntryFormTagSearch,
            selectedCustomCategories: customCategories,
            newCustomCategoryNames: $newCustomCategoryNamesFromEntryFormAttributeSearch,
            newCustomCategoryElementsWithExistingParents: $newCustomCategoryElementsWithExistingParentFromEntryFormAttributeSearch,
            newCustomCategoryElementsWithNewParents: $newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch
        };

        dispatch('edit')

        //console.log(note);

        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/notes/${$selectedEntry._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(note)
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Server error: ${response.status} - ${errorText}`);
            }

            const responseData = await response.json();
            //console.log('Note updated successfully:', responseData);

            entries.update(currentEntries => {
                return currentEntries.map(entry => {
                    if (entry._id === responseData._id) {
                        return {
                            ...entry,
                            title: responseData.title,
                            value: responseData.content,
                            contentType: responseData.contentType,
                            categories: responseData.categories,
                            statuses: responseData.statuses,
                            tags: responseData.tags,
                            customCategories: responseData.customCategories,
                            timestamp: responseData.timestamp,
                            metadata: responseData.metadata
                        };
                    }
                    return entry;
                });
            });

            if ($newTagsFromEntryFormTagSearch.length > 0) {
                await fetchTags();
            }
            if ($newStatusesFromEntryFormStatusSearch.length > 0) {
                await fetchStatuses();
            }

            if ($newCustomCategoryElementsWithExistingParentFromEntryFormAttributeSearch.length > 0 || $newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch.length > 0) {
                await fetchCustomCategoryNames();
                await fetchCustomCategoryElements();
                buildCustomCategories();
            }

            let tempCustomCategories = [...responseData.customCategories]

            $newCustomCategoryElementsWithExistingParentFromEntryFormAttributeSearch.forEach(([tempEl, tempPar]) => {
                const tempList = $customCategoryMap.get(tempPar) as CustomCategoryElement[];
                const item = tempList.find((item) => item.name === tempEl);
                if (item) {
                    tempCustomCategories = [...tempCustomCategories, item._id];
                }
            });

            $newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch.forEach(([tempEl, tempPar]) => {
                for (const [_, parent] of $customCategoryNames) {
                    if (parent.name === tempPar) {
                        const tempList = $customCategoryMap.get(parent._id) as CustomCategoryElement[];
                        const item = tempList.find((item) => item.name === tempEl);
                        if (item) {
                            tempCustomCategories = [...tempCustomCategories, item._id];
                        }
                        break
                    }
                }
            });

            $selectedEntry.title = responseData.title;
            $selectedEntry.value = responseData.content;
            $selectedEntry.categories = responseData.categories;
            $selectedEntry.statuses = responseData.statuses;
            $selectedEntry.tags = responseData.tags;
            $selectedEntry.customCategories = tempCustomCategories;

            entryFormSelectedCategoriesIds.set([])
            entryFormSelectedTagsIds.set([])
            entryFormSelectedStatusesIds.set([])
            rowsCustomCat.set([{ id: 0, filled: false, parentId: '', elementId: '', newPar: false, newEl: false}]);
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    entryFormSelectedCategoriesIds.set(entry.categories);
    entryFormSelectedTagsIds.set(entry.tags);
    entryFormSelectedStatusesIds.set(entry.statuses);
    rowsCustomCat.set((() => {
        if (entry.customCategories.length == 0) {
            return [{ id: 0, filled: false, parentId: '', elementId: '', newPar: false, newEl: false}];
        } else {
            const temp:{id: number; filled: boolean; parentId: string; elementId: string, newPar: boolean, newEl: boolean }[] = [];
            let tempId = 0;
            entry.customCategories.forEach((elementId) => {
                const parentId = ($customCategoryElements.get(elementId) as CustomCategoryElement).parentId;
                temp.push({ id: tempId, filled: true, parentId: parentId, elementId: elementId, newPar: false, newEl: false})
                tempId++;
            });
            return [...temp, { id: tempId, filled: false, parentId: '', elementId: '', newPar: false, newEl: false}];
        }
    })());
</script>

<div class="modal-header">
    <div class="d-flex justify-content-between align-items-center">
        <input class="text-border flex-grow-1 me-2 px-2 py-1 border rounded" type="text" bind:value={entryTitle}/>

        <div class="ps-3">
            <button class="modal-button" on:click|stopPropagation={() => dispatch('edit')}>
                <svg width="24" height="20">
                    <use href="#icon-edit-simple"/>
                </svg>
            </button>
            <button class="modal-button" on:click|stopPropagation={() => dispatch('delete')}>
                <svg width="24" height="20">
                    <use href="#icon-trash"/>
                </svg>
            </button>
        </div>
    </div>
    <button class="modal-button" on:click|stopPropagation={() => dispatch('close')}>
        <svg width="24" height="20">
            <use href="#icon-close"/>
        </svg>
    </button>
</div>

<div class="modal-body d-flex flex-column h-100 position-relative mt-3">
    <textarea
            bind:value={entryValue}
            class="text-border flex-grow-1 me-2 px-2 py-1 border rounded h-100"
            style="resize: none;"
    />
</div>

<div class="mt-2">
    <EntryFormCateorySearch/>
    <EntryFormTagStatusSearch/>
    <EntryFormStatusSearch/>
    <EntryFormAtributeSearch/>
</div>
<div class="modal-footer">
    <button class="btn btn-secondary" on:click|stopPropagation={() => dispatch('edit')}>Cancel</button>
    <button class="btn btn-primary ms-2" on:click|stopPropagation={() => submitEdit()}>Submit</button>
</div>

<style>
    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .modal-title {
        margin: 0;
    }

    .modal-button {
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        transition: transform 0.05s ease-in-out;
    }

    .modal-button:hover {
        transform: scale(1.1);
    }

    .title {
        gap: 0.15rem;
    }

    .types {
        background-color: #d4dae5;
        margin-bottom: 0.5rem;
        padding: 0.15rem 0.25rem;
        margin-left: 0.5rem;
    }

    .chip {
        margin-left: 0.25rem;
        height: 2rem;
        background-color: #6d7583;
    }

</style>