<script lang="ts">
    import {format} from "date-fns";
    import type {Entry} from "../store";
    import {isEditingModal, selectedEntry, showModal} from "../store";
    import {handleMetadataUpdate} from "./services/fetchMetaDataService";

    export let entry: Entry;

    function openModal(selected: Entry) {
        selectedEntry.set(selected);
        showModal.set(true);
    }

    async function updateMetadata() {
        const updatedMetadata = await handleMetadataUpdate(entry.url, entry._id);
        if (updatedMetadata) {
            // Trigger reactivity
            entry.metadata = {
                ...entry.metadata,
                ...updatedMetadata
            };

            entry = { ...entry };
        }
    }

    function makeFullUrl(inputUrl: string): string {
        try {
            // Check if input URL starts with a protocol (http/https)
            if (!/^https?:\/\//i.test(inputUrl)) {
                inputUrl = `https://${inputUrl}`;
            }

            // Create a URL object to parse the input URL
            const url = new URL(inputUrl);

            return url.toString();
        } catch (error) {
            console.error("Invalid URL:", error);
            throw new Error("Provided input is not a valid URL.");
        }
    }

</script>

<div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-3 d-flex">
    <div class="card h-100 w-100 entry-card">
        <div class="card-body d-flex flex-column">
            <div class="d-flex justify-content-between align-items-center text-truncate">
                <h5 class="card-title">{entry.title || '\u00A0'}</h5>

                <div class="button-container">
                    <button class="edit-button" on:click|stopPropagation={() => {isEditingModal.set(true); openModal(entry);}}>
                        <svg width="24" height="20">
                            <use href="#icon-edit-simple"/>
                        </svg>
                    </button>
                    <button class="expand-button" on:click|stopPropagation={() => {isEditingModal.set(false); openModal(entry)}}>
                        <svg width="24" height="20">
                            <use href="#arrows-out"/>
                        </svg>
                    </button>
                </div>
            </div>


            {#if entry.contentType === 'Note'}
                <div class="w-100 mb-3">
                    <p class="card-text text-wrap">
                        {entry.value}
                    </p>
                </div>

            {:else if entry.contentType === 'Bookmark'}
                <div class="bookmark-preview mb-3">
                    <div class="ratio ratio-16x9">

                        <a href="{entry.metadata.url ? entry.metadata.url : makeFullUrl(entry.url)}" target="_blank" rel="noopener noreferrer" on:click={() => updateMetadata()}>
                            {#key entry.metadata.image}
                                <img
                                        src="{!entry.metadata.image || entry.metadata.image.trim() === ''
                                               ? '/loading.jpg'
                                               : entry.metadata.image.startsWith('data:image/')
                                                   ? entry.metadata.image // Use base64-encoded image directly
                                                   : `${import.meta.env.VITE_LINK}/proxy-image?url=${encodeURIComponent(entry.metadata.image)}`}"
                                        alt="preview"
                                        class="w-100 h-100 object-fit-cover rounded shadow"
                                />
                            {/key}
                        </a>
                    </div>
                </div>
            {/if}
            <span class="entry-date">{format(entry.timestamp, "MMM d ''yy")}</span>
        </div>
    </div>
</div>

<style>
    .edit-button, .expand-button {
        all: unset;
        cursor: pointer;
        padding-bottom: 0.5rem;
        margin-left: 0.5rem;
        visibility: hidden;
        font-size: 0.95rem;
        transition: transform 0.05s ease-in-out;
    }
    .edit-button:hover {
        transform: scale(1.1);
    }
    .expand-button:hover {
        transform: scale(1.15);
    }
    .card-body:hover .edit-button, .card-body:hover .expand-button{
        visibility: visible;
    }

    .button-container {
        display: none;
        align-items: center;
    }
    .card-body:hover .button-container {
        display: flex;
    }

    .card-title {
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 1;
        transition: flex-basis 0.3s ease;
    }
    .card-body:hover .card-title {
        flex-basis: auto;
    }

    .entry-card {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        border-radius: 5px;
        background-color: #e0e6ef;
        min-height: 180px;
        min-width: 150px;
        color: #000000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
    }

    .entry-date {
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
        font-size: 0.8rem;
        color: #888;
    }

    .card-text {
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 12rem;
        line-height: 1.5rem;
        text-align: justify;
        font-size: 0.85rem;
    }
</style>