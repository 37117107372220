<script lang="ts">
    import {onDestroy, onMount, tick} from "svelte";
    import {entries, selectedTags, type Tag, tags} from '../store';
    import {fetchTags} from "./services/fetchTagsService";
    import {fetchNotes} from "./services/fetchNoteService";
    import InputField from "./InputField.svelte";

    let searchQuery = '';
    let isAddingNew = false;
    let isDropDownOpen = false;
    let container: HTMLElement;
    let inputRef: HTMLElement;
    let newElementName = '';
    let editElementName = '';
    let editElementId = '';
    let validTags: Tag[] = [];

    async function handleAddNewElement() {
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/tags`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({name: newElementName})
            });
        } catch (error) {
            console.error(error);
        }
    }

    function addTagSelection(tagId: string): void {
        selectedTags.update(currentTags => {
            searchQuery = ''
            return [...currentTags, tagId];
        });
    }

    $: validTags = (() => {
        const valid: Tag[] = [];
        $tags.forEach((tag, key) => {
            if (!$selectedTags.includes(key)) {
                if (tag.name.toLowerCase().includes(searchQuery.toLowerCase())) {
                    valid.push(tag);
                }
            }
        });
        return valid;
    })();

    async function handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            isAddingNew = false;
            if (newElementName !== '') {
                await handleAddNewElement();
                await fetchTags();
                newElementName = '';
            }
        }
    }

    const handleOutsideClick = (event: MouseEvent) => {
        if (container && !container.contains(event.target as Node)) {
            isDropDownOpen = false;
            isAddingNew = false;
            newElementName = '';
        }
    };

    async function handleAddNew() {
        isAddingNew = true;
    }

    async function deleteTag(id: string) {
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/tags/${id}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error('Failed to delete tag');
            }
            await fetchTags();
            entries.set(await fetchNotes());
        } catch (error) {
            console.error("Error deleting tag:", error);
        }
    }

    async function editTag(id: string, newName: string) {
        try {
            if (newName !== ($tags.get(id) as Tag).name) {
                const response = await fetch(`${import.meta.env.VITE_LINK as string}/tags/${id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({name: newName})
                });
                if (!response.ok) {
                    throw new Error('Failed to update the tag.');
                }
                await fetchTags();
                entries.set(await fetchNotes());
            }
        } catch (error) {
            console.error("Error editing tag:", error);
        }
    }

    function handleMouseDown(event: MouseEvent) {
        if (editElementId !== '' && event.target !== inputRef) {
            const temp1 = editElementId;
            const temp2 = editElementName;
            inputRef.blur()
            editTag(temp1, temp2);
        }
    }

    function handleKey(event: KeyboardEvent) {
        if (editElementId !== '' && event.key === 'Escape') {
            inputRef.blur();
        } else if (editElementId !== '' && event.key === 'Enter') {
            const temp1 = editElementId;
            const temp2 = editElementName;
            inputRef.blur();
            editTag(temp1, temp2);
        }
    }

    $: if (inputRef) {
        inputRef.focus();
    }

    async function handleInputSubmit (event: CustomEvent) {
        isAddingNew = false
        newElementName = event.detail.value;
        await handleAddNewElement();
        await fetchTags();
    }

    function handleInputCancel () {
        isAddingNew = false
    }


    onMount(() => {
        document.addEventListener('click', handleOutsideClick);
        window.addEventListener('mousedown', handleMouseDown);
        window.addEventListener('keydown', handleKey);
    });

    onDestroy(() => {
        document.removeEventListener('click', handleOutsideClick);
        window.removeEventListener('mousedown', handleMouseDown);
        window.removeEventListener('keydown', handleKey);
    });
</script>

<div class="dropdown" bind:this={container}>
    <div class="search position-relative">
        <input
                type="text"
                placeholder="Search Tags"
                bind:value={searchQuery}
                class="search-input rounded bg-dark"
                on:focus={() => isDropDownOpen = true}
        />
        {#if isDropDownOpen}
            <ul class="custom-dropdown-menu rounded">
                {#each validTags as tag}
                    <li class="element">
                        {#if editElementId !== tag._id}
                            <div class="d-flex align-items-left">
                                <button class="dropdown-item rounded"
                                        on:click={() => addTagSelection(tag._id)} data-testid="tags-dropdown">
                                    {tag.name}
                                </button>

                                <div class="action-buttons d-flex">
                                    <button class="category_buttons" on:click|stopPropagation={() => {editElementId = tag._id; editElementName = tag.name}}>
                                        <svg width="16" height="16">
                                            <use href="#icon-edit-simple" />
                                        </svg>
                                    </button>
                                    <button class="category_buttons" on:click|stopPropagation={() => deleteTag(tag._id)}>
                                        <svg width="16" height="16">
                                            <use href="#icon-trash" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        {:else}
                            <input
                                    type="text"
                                    bind:value={editElementName}
                                    bind:this={inputRef}
                                    class=""
                                    on:blur={() => {editElementId = ''; editElementName = ''}}
                            />
                        {/if}
                    </li>
                {/each}
                <li>
                    {#if !isAddingNew}
                        <button class="custom-button" on:click|stopPropagation={() => {
                        isAddingNew = true;
                        handleAddNew();
                    }}>
                            +
                        </button>
                    {:else}
                        <InputField on:submit={handleInputSubmit} on:cancel={handleInputCancel}/>
                    {/if}
                </li>
            </ul>
        {/if}
    </div>
</div>

<style>

    .action-buttons {
        /*margin-left: auto;  Push action buttons to the right, if desired */
        visibility: hidden; /* Default hidden */
    }

    .action-buttons button {
        background: none;
        border: none;
        color: #fff;
        font-size: 16px;
        margin-right: 8px;
        cursor: pointer;
    }

    .action-buttons button:hover {
        color: #fff;
    }

    .element:hover .action-buttons {
        visibility: visible; /* Show on hover */
    }

    .custom-button {
        all: unset;
        cursor: pointer;
        text-align: center;
        padding-left: 0.75rem;
    }

    .search-input {
        border: 2px solid #2c313a; /* Set the border to solid white */
        padding: 0.5rem; /* Padding inside the input */
        width: 33vw; /* Set a specific width */
        background-color: #343a40; /* Match the dark background */
        color: #ffffff; /* Set text color to white */
    }

    .search-input::placeholder {
        color: #ffffff;
    }

    .custom-dropdown-menu {
        position: absolute;
        margin-top: 5px;
        background-color: #343a40;
        border-radius: 2px;
        padding: 10px;
        list-style: none;
        z-index: 1001;
        min-width: 100%;
        max-height: 35vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .dropdown-item {
        all: unset;
        color: #ddd;
        padding: 5px 15px;
        text-decoration: none;
        /*display: block;*/
        cursor: pointer;

    /*white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;*/
}

    .dropdown-item:hover {
        background-color: #4a505e;
        color: white;
    }

    .dropdown-item.selected {
        background-color: #4a505e;
        color: white;
    }

    .dropdown {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 35vw;
        width: 33vw;
    }
</style>