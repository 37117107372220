<script lang="ts">
    import {
        type CustomCategoryElement,
        customCategoryElements,
        customCategoryMap,
        type CustomCategoryName,
        customCategoryNames, newCustomCategoryElementsWithExistingParentFromEntryFormAttributeSearch,
        newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch,
        newCustomCategoryNamesFromEntryFormAttributeSearch,
        rowsCustomCat,
    } from "../store";
    import {createEventDispatcher, onDestroy, onMount} from "svelte";

    export let row: {id: number; filled: boolean; parentId: string; elementId: string; newPar: boolean;newEl: boolean};
    export let selectedElements: Set<string>;

    const dispatch = createEventDispatcher();

    let isAttributeDropdownOpen = false;
    let isAttributeInputDisabled = false;
    let isValueDropdownOpen = false;
    let isValueInputDisabled = true;
    let selectedCustomCategoryParentId = '';
    let selectedCustomCategoryElementId = '';
    let attributeSearchQuery = '';
    let valueSearchQuery = '';
    let emptyAttributePlaceholder = 'Attribute';
    let emptyValuePlaceholder = 'Value';
    let attributePlaceholder = emptyAttributePlaceholder;
    let valuePlaceholder = emptyValuePlaceholder;
    let attributeInputElement: HTMLElement;
    let valueInputElement: HTMLElement;
    let attributeContainer: HTMLElement;
    let valueContainer: HTMLElement;
    let validNames: CustomCategoryName[] = [];
    let validElements: CustomCategoryElement[] = [];
    let validNewNames: string[] = [];
    let validNewElements: string[][] = [];
    let addingNewParent: boolean = false;
    let addingNewElement: boolean = false;

    $: validNames = (() => {
        const valid: CustomCategoryName[] = [];
        $customCategoryNames.forEach((name, _) => {
            if (name.name.toLowerCase().includes(attributeSearchQuery.toLowerCase())) {
                valid.push(name);
            }
        });
        return valid;
    })();

    $: validElements = (() => {
        let valid: CustomCategoryElement[] = [];
        if (selectedCustomCategoryParentId !== '') {
            if (!addingNewParent) {
                valid = ($customCategoryMap.get(selectedCustomCategoryParentId) as CustomCategoryElement[]).
                filter(element => !selectedElements.has(element._id) && element.name.toLowerCase().includes(valueSearchQuery.toLowerCase()))

            }
        }
        return valid;
    })();

    $: validNewNames = $newCustomCategoryNamesFromEntryFormAttributeSearch.filter(name => name.toLowerCase().includes(attributeSearchQuery.toLowerCase()));

    $: validNewElements = (() => {
        let valid: string[][] = [];
        if (selectedCustomCategoryParentId !== '') {
            const temp1 = $newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch.filter(([el, par]) => par === selectedCustomCategoryParentId && el.toLowerCase().includes(valueSearchQuery.toLowerCase()));
            const temp2 = $newCustomCategoryElementsWithExistingParentFromEntryFormAttributeSearch.filter(([el, par]) => par === selectedCustomCategoryParentId && el.toLowerCase().includes(valueSearchQuery.toLowerCase()));
            valid = [...temp1, ...temp2];
        }
        return valid;
    })();

    const handleOutsideClick = (event: MouseEvent) => {
        if (attributeContainer && isAttributeDropdownOpen && !attributeContainer.contains(event.target as Node)) {
            isAttributeDropdownOpen = false;
        }
        if (valueContainer && isValueDropdownOpen && !valueContainer.contains(event.target as Node)) {
            isValueDropdownOpen = false;
        }
    };

    function handleFilled() {
        if (selectedCustomCategoryElementId !== '') {
            dispatch('filled', {
                isFilled: true,
                parentId: selectedCustomCategoryParentId,
                elementId: selectedCustomCategoryElementId,
                newPar: addingNewParent,
                newEl: addingNewElement
            });
        }
    }

    function handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            if (isAttributeDropdownOpen) {
                if (attributeSearchQuery !== '') {
                    const item = validNames.find(cat => cat.name.toLowerCase() === attributeSearchQuery);
                    if (item) {
                        attributeSearchQuery = item.name;
                        selectedCustomCategoryParentId = item._id;
                        addingNewParent = false;
                        isAttributeDropdownOpen = false;
                        valueInputElement.focus();
                    } else {
                        selectedCustomCategoryParentId = attributeSearchQuery;
                        addingNewParent = true;
                        isAttributeDropdownOpen = false;
                        valueInputElement.focus();
                    }
                }
            } else if (isValueDropdownOpen) {
                if (valueSearchQuery !== '') {
                    const item = validElements.find(el => el.name.toLowerCase() === valueSearchQuery);
                    if (item) {
                        valueSearchQuery = item.name;
                        selectedCustomCategoryElementId = item._id;
                        addingNewElement = false;
                        isValueDropdownOpen = false;
                        valueInputElement.blur();
                        handleFilled();
                    } else {
                        selectedCustomCategoryElementId = valueSearchQuery;
                        addingNewElement = true;
                        isValueDropdownOpen = false;
                        valueInputElement.blur();
                        handleFilled();
                    }
                }
            }
        } else if (event.key === 'Escape') {
            if (isValueDropdownOpen) {
                isValueDropdownOpen = !isValueDropdownOpen;
                valueInputElement.blur();
            } else if (isAttributeDropdownOpen) {
                isAttributeDropdownOpen = !isAttributeDropdownOpen;
                attributeInputElement.blur();
            }
        }
    }

    function handleMouseDown(event: MouseEvent) {
        if (isAttributeDropdownOpen) {
            if (event.target !== attributeInputElement) {
                attributeInputElement.blur();
                handleAttributeSelect();
                if (valueSearchQuery !== '') {
                    handleValueSelect();
                }
            }
        } else if (isValueDropdownOpen) {
            if (event.target !== valueInputElement) {
                valueInputElement.blur();
                handleValueSelect();
            }
        }
    }

    function handleAttributeSelect() {
        const item = validNames.find(item => item.name.toLowerCase() === attributeSearchQuery.toLowerCase())
        if (item) {
            attributeSearchQuery = item.name;
            selectedCustomCategoryParentId = item._id;
            addingNewParent = false;
        } else {
            selectedCustomCategoryParentId = attributeSearchQuery;
            addingNewParent = true;
        }
    }

    function handleValueSelect() {
        const item = validElements.find(item => item.parentId === selectedCustomCategoryParentId && item.name === valueSearchQuery);
        if (item) {
            valueSearchQuery = item.name
            selectedCustomCategoryElementId = item._id;
            addingNewElement = false;
            handleFilled();
        } else {
            selectedCustomCategoryElementId = valueSearchQuery;
            addingNewElement = true;
            handleFilled();
        }
    }

    if (row.elementId !== '') {
        if (row.newPar) {
            attributeSearchQuery = row.parentId;
            valueSearchQuery = row.elementId;
        } else if (row.newEl) {
            attributeSearchQuery = ($customCategoryNames.get(row.parentId) as CustomCategoryName).name;
            valueSearchQuery = row.elementId;
        } else {
            const temp = $customCategoryElements.get(row.elementId) as CustomCategoryElement
            valueSearchQuery = temp.name;
            attributeSearchQuery = ($customCategoryNames.get(row.parentId) as CustomCategoryName).name;
        }
    }

    onMount(() => {
        document.addEventListener('click', handleOutsideClick);
        window.addEventListener('mousedown', handleMouseDown);
    });

    onDestroy(() => {
        document.removeEventListener('click', handleOutsideClick);
        window.addEventListener('mousedown', handleMouseDown);
    });
</script>

<div class="container d-flex">
    <div bind:this={attributeContainer}>
        <input
                type="text"
                placeholder={attributePlaceholder}
                disabled={isAttributeInputDisabled}
                class="form-control"
                bind:value={attributeSearchQuery}
                bind:this={attributeInputElement}
                on:keydown={handleKeyDown}
                on:mousedown={handleMouseDown}
                on:focus={() => {isAttributeDropdownOpen = true; isValueInputDisabled = false}}
        />
        {#if isAttributeDropdownOpen && (validNames.length > 0 || validNewNames.length > 0)}
            <div class="custom-dropdown-menu d-flex flex-column align-items-start" style={`width: ${attributeInputElement.clientWidth}px;`}>
                {#each validNames as customCategoryName}
                    <button class="custom-button" on:click|stopPropagation={() => {
                    attributeSearchQuery = customCategoryName.name;
                    selectedCustomCategoryParentId = customCategoryName._id;
                    isAttributeDropdownOpen = false;
                    addingNewParent = false;
                    valueInputElement.focus();
                }}>
                        {customCategoryName.name}
                    </button>
                {/each}
                {#each validNewNames as customCategoryName}
                    <button class="custom-button" on:click|stopPropagation={() => {
                    attributeSearchQuery = customCategoryName;
                    selectedCustomCategoryParentId = customCategoryName;
                    isAttributeDropdownOpen = false;
                    addingNewParent = true;
                    valueInputElement.focus();
                }}>
                        {customCategoryName}
                    </button>
                {/each}
            </div>
        {/if}
    </div>
    <div class="value-container" bind:this={valueContainer}>
        <input
                type="text"
                placeholder={valuePlaceholder}
                class="form-control"
                bind:value={valueSearchQuery}
                bind:this={valueInputElement}
                on:keydown|stopPropagation={handleKeyDown}
                on:focus={() => {isValueDropdownOpen = true;}}
        />
        {#if isValueDropdownOpen && validElements.length > 0}
            <div class="custom-dropdown-menu d-flex flex-column align-items-start" style={`width: ${valueInputElement.clientWidth}px;`}>
                {#each validElements as customCategoryElement}
                    <button class="custom-button" on:click={() => {
                            valueSearchQuery = customCategoryElement.name;
                            isValueDropdownOpen = false;
                            selectedCustomCategoryElementId = customCategoryElement._id;
                            addingNewElement = false;
                            handleFilled();
                        }}>
                        {customCategoryElement.name}
                    </button>
                {/each}
            </div>
        {/if}
    </div>
    {#if attributeSearchQuery !== '' || valueSearchQuery !== ''}
        <button class="custom-button" on:click|stopPropagation={() => {
                attributeSearchQuery = '';
                valueSearchQuery = '';
                isValueInputDisabled = true;
                isValueDropdownOpen = false;
                dispatch('removeRow');
            }}>
            x
        </button>
    {/if}
</div>

<style>
    .container {
        padding-left: 1rem;
        padding-bottom: 0.25rem;
    }

    .custom-dropdown-menu {
        position: absolute;
        margin-top: 1px;
        background-color: #a5a8b6;
        border-radius: 2px;
        padding: 10px;
        list-style: none;
        z-index: 1001;
        max-height: 25vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .custom-button {
        all: unset;
        cursor: pointer;
        text-align: center;
        padding-left: 0.75rem;
    }

    .value-container {
        padding-left: 0.5rem;
    }

</style>