<script lang="ts">
    import EntryFormAttributeSearchComponent from './EntryFormAttributeSearchComponent.svelte';
    import {newCustomCategoryElementsWithExistingParentFromEntryFormAttributeSearch, rowsCustomCat} from "../store";
    import {
        newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch,
        newCustomCategoryNamesFromEntryFormAttributeSearch
    } from "../store.js";

    let selectedElementIds = new Set<string>();

    newCustomCategoryNamesFromEntryFormAttributeSearch.set([]);
    newCustomCategoryElementsWithExistingParentFromEntryFormAttributeSearch.set([]);
    newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch.set([]);

    function addRow(id: number) {
        rowsCustomCat.update(items => {
            return [...items, { id: id + 1, filled: false, parentId: '', elementId: '', newPar: false, newEl: false }];
        })
    }

    function handleFilled(id: number, filledStatus: boolean, parentId: string, elementId: string, newPar: boolean, newEl: boolean) {
        const row = $rowsCustomCat.find(r => r.id === id);
        if (row) {
            row.filled = filledStatus;
            row.newPar = newPar;
            row.newEl = newEl;
            row.parentId = parentId;
            row.elementId = elementId;
        }

        newCustomCategoryNamesFromEntryFormAttributeSearch.set(
            Array.from(new Set($rowsCustomCat.filter(row => row.elementId !== '' && row.newPar).map(row => row.parentId)))
        );
        newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch.set($rowsCustomCat.filter(row => row.elementId !== '' && row.newPar).map((row) => [row.elementId, row.parentId]));
        newCustomCategoryElementsWithExistingParentFromEntryFormAttributeSearch.set($rowsCustomCat.filter(row => row.elementId !== '' && !row.newPar && row.newEl).map((row) => [row.elementId, row.parentId]));

        updateSelected()
        const allRowsFilled = $rowsCustomCat.every(row => row.filled);

        if (allRowsFilled) {
            addRow($rowsCustomCat[$rowsCustomCat.length - 1].id);
        }
    }

    function removeRow(id: number) {
        if ($rowsCustomCat.length === 1) {
            rowsCustomCat.set([{ id: 0, filled: false, parentId: '', elementId: '', newPar: false, newEl: false }]);
            updateSelected();
        } else {
            const row = $rowsCustomCat.find(r => r.id === id);
            if (row) {
                if (row.newPar) {
                    newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch.set($newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch.filter(([el, par]) => el !== row.elementId && row.parentId !== par));
                    newCustomCategoryNamesFromEntryFormAttributeSearch.set($newCustomCategoryNamesFromEntryFormAttributeSearch.filter(name => $newCustomCategoryElementsWithNewParentFromEntryFormAttributeSearch.some(item => item[1] === name)));
                } else if (row.newEl) {
                    newCustomCategoryElementsWithExistingParentFromEntryFormAttributeSearch.set($newCustomCategoryElementsWithExistingParentFromEntryFormAttributeSearch.filter(([el, par]) => el !== row.elementId && row.parentId));
                }
            }
            rowsCustomCat.update(items => {
                return $rowsCustomCat.filter(row => row.id !== id);
            })
            updateSelected()

            const allRowsFilled = $rowsCustomCat.every(row => row.filled);
            if (allRowsFilled) {
                addRow($rowsCustomCat[$rowsCustomCat.length - 1].id);
            }
        }
    }

    function updateSelected(){
        selectedElementIds = new Set($rowsCustomCat.filter(row => row.filled && !row.newEl).map(row => row.elementId));
    }

    updateSelected()
</script>

<div>
    {#each $rowsCustomCat as row (row.id)}
        <EntryFormAttributeSearchComponent
                row={row}
                selectedElements={selectedElementIds}
                on:filled={(event) => handleFilled(row.id, event.detail.isFilled, event.detail.parentId, event.detail.elementId, event.detail.newPar, event.detail.newEl)}
                on:removeRow={() => removeRow(row.id)}
        />
    {/each}
</div>
