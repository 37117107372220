import { writable } from 'svelte/store';
import { navigate } from "svelte-routing";
import {resetStores} from "../../store";

export const loggedIn = writable(false);

export async function handleLogin(email: string, password: string) {
    try {
        const response = await fetch(`${import.meta.env.VITE_LINK as string}/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ email, password })
        });

        if (response.ok) {
            console.log('Login successful');
            loggedIn.set(true);
            navigate('/');
            return { success: true };
        } else {
            const errorData = await response.json();
            console.error('Login failed:', errorData.message);
            loggedIn.set(false);
            return { error: errorData.message };
        }
    } catch (error) {
        console.error('Error during login:', error);
        return { error: 'An error occurred during login.' };
    }
}


async function onFormSubmit(event) {
    event.preventDefault();
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    await handleLogin(email, password);
}

export async function handleLogout() {
    try {
        loggedIn.set(false);
        resetStores()
        navigate('/login');
        const response = await fetch(`${import.meta.env.VITE_LINK as string}/logout`, {
            method: 'POST',
            credentials: 'include',
        });

        if (response.ok) {
            console.error('Logout successful');
        } else {
            console.error('Logout failed');
        }
    } catch (error) {
        console.error('Logout error:', error);
    }
}


export async function getLoggedInStatus(): Promise<unknown> {
    return new Promise((resolve) => {
        setTimeout(() => {
            let status: boolean;
            loggedIn.subscribe(value => {
                status = value;
            });
            resolve(status);
        }, 500);
    });
}