import {type CustomCategoryName, customCategoryNames} from "../../store";

export async function fetchCustomCategoryNames() {
    try {
        const response = await fetch(`${import.meta.env.VITE_LINK as string}/customcategorynames`);
        if (response.ok) {
            const customRes = await response.json();
            const customCatNameList: CustomCategoryName[] = await Promise.all(
                customRes.map(async (customCategoryName: any) => {
                    return {
                        _id: customCategoryName._id,
                        name: customCategoryName.name
                    };
                })
            );
            customCategoryNames.set(new Map(customCatNameList.map(name => [name._id, name])));
        } else {
            console.error("Failed to fetch customCategoryNames");
        }
    } catch (error) {
        console.error("Error fetching customCategoryNames:", error);
    }
}