import {type CustomCategoryElement, customCategoryElements} from "../../store";

export async function fetchCustomCategoryElements() {
    try {
        const response = await fetch(`${import.meta.env.VITE_LINK as string}/customcategoryelements`);
        if (response.ok) {
            const customRes = await response.json();
            const customCatElementList: CustomCategoryElement[] = await Promise.all(
                customRes.map(async (customCategoryElement: any) => {
                    return {
                        _id: customCategoryElement._id,
                        name: customCategoryElement.name,
                        parentId: customCategoryElement.parentId
                    };
                })
            );
            customCategoryElements.set(new Map(customCatElementList.map(element => [element._id, element])));
        } else {
            console.error("Failed to fetch customCategoryNames");
        }
    } catch (error) {
        console.error("Error fetching customCategoryNames:", error);
    }
}