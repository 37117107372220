<script lang="ts">
    import {
        entryFormSelectedTagsIds,
        entryFormSelectedStatusesIds,
        entryFormSelectedCategoriesIds,
        rowsCustomCat,
        entryFormCategorySearchQuery,
        selectedCategories,
        selectedTags,
        selectedStatuses, selectedCustomCategories, selectedCustomCategoryBarIds
    } from "../store";
    import { parse, isValid, format } from 'date-fns';
    import {createEventDispatcher, onMount} from 'svelte';
    import EntryFormCateorySearch from "./EntryFormCateorySearch.svelte";
    import EntryFormAtributeSearch from "./EntryFormAttributeSearch.svelte";
    import EntryFormTagStatusSearch from "./EntryFormTagSearch.svelte";
    import EntryFormStatusSearch from "./EntryFormStatusSearch.svelte";

    const dispatch = createEventDispatcher();

    let contentType: 'Note' | 'Bookmark' = 'Note';
    let previousContentType: 'Note' | 'Bookmark' = contentType;

    let title = '';
    let content = '';

    let url = '';
    let lastFetchedURL = '';
    let isURLValid = false;

    let debounceTimeout: ReturnType<typeof setTimeout> | undefined;

    let showURLErrorMessage = false;
    let showContentErrorMessage = false;
    let showCategoryErrorMessage = false;

    let hasContentBeenTouched = false;
    let hasCategoryBeenTouched = false;
    let hasURLBeenTouched = false;

    // Initialize timestamp as the current date
    let timestamp = new Date().toISOString().split("T")[0];

    // Update the title field when the user stops typing for some time
    function debouncedFetch(url: string) {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(async () => {
            if (isURLValid && url !== lastFetchedURL) {
                lastFetchedURL = url;
                title = await fetchTitle(url);
            }
        }, 500);  // timeout length
    }

    // Fetch title method
    async function fetchTitle(url: string) {
        try {
            // Fetch the title
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/api/title?url=${encodeURIComponent(url)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            // Check response validity
            if (!response.ok) {
                const error = await response.json();
                console.error('Error fetching title:', error.error);
                return "";
            }

            // Return the title
            const data = await response.json();
            return data.title || "";
        } catch (error) {
            console.error('Error fetching title:', error);
            return "";
        }
    }

    function submitForm() {
        hasContentBeenTouched = true;
        hasCategoryBeenTouched = true;
        hasURLBeenTouched = true;

        showContentErrorMessage = contentType === 'Note' && content.trim() === '';
        showCategoryErrorMessage = $entryFormSelectedCategoriesIds.length === 0;
        showURLErrorMessage = contentType === 'Bookmark' && !isURLValid;

        if (showContentErrorMessage || showCategoryErrorMessage) {
            return;
        }
        showContentErrorMessage = false;
        showCategoryErrorMessage = false;
        showURLErrorMessage = false;

        dispatch('submit', {
            title,
            content,
            url,
            contentType,
            selectedCategories: $entryFormSelectedCategoriesIds,
            statuses: $entryFormSelectedStatusesIds,
            tags: $entryFormSelectedTagsIds,
            selectedCustomCategoriesForSubmit: $rowsCustomCat.filter(row => row.elementId !== '' && !(row.newPar || row.newEl)).map(row => ({
                _id: row.elementId
            })),
            timestamp: new Date(timestamp) // Convert timestamp back to Date object
        });

        selectedCategories.set($entryFormSelectedCategoriesIds);
        selectedTags.set($entryFormSelectedTagsIds);
        selectedStatuses.set($entryFormSelectedStatusesIds);
        let temp: string[] = [];
        selectedCustomCategories.set(
            $rowsCustomCat
                .filter(row => row.elementId !== '' && !(row.newPar || row.newEl))
                .map(({ elementId, parentId }) => {
                    if (temp.length < 2) {
                        if (!temp.includes(parentId)) {
                            temp.push(parentId);
                        }
                        return [elementId, parentId];
                    } else if (temp.includes(parentId)) {
                        return [elementId, parentId];
                    }
                    return null;
                })
                .filter((item): item is string[] => item !== null)
        );
        if (temp.length === 0) {

        } else if (temp.length === 1) {
            selectedCustomCategoryBarIds.set([temp[0], '']);
        } else {
            selectedCustomCategoryBarIds.set(temp);
        }

        // Reset form fields
        entryFormSelectedCategoriesIds.set([]);
        entryFormSelectedTagsIds.set([]);
        entryFormSelectedStatusesIds.set([]);
        rowsCustomCat.set([{ id: 0, filled: false, parentId: '', elementId: '' , newPar: false, newEl: false}]);
        entryFormCategorySearchQuery.set('')
        contentType = 'Note';
        timestamp = new Date().toISOString().split("T")[0];

        resetFields();
    }

    function resetFields() {
        content = "";
        title = "";
        url = "";
        lastFetchedURL = "";

        isURLValid = false;

        showURLErrorMessage = false;
        showContentErrorMessage = false;
        showCategoryErrorMessage = false;

        hasContentBeenTouched = false;
        hasCategoryBeenTouched = false;
        hasURLBeenTouched = false;
    }

    function handleContentInput() {
        hasContentBeenTouched = true;
    }

    function handleURLInput() {
        hasURLBeenTouched = true;
    }

    function cancelForm() {
        entryFormSelectedCategoriesIds.set([]);
        entryFormSelectedTagsIds.set([]);
        entryFormSelectedStatusesIds.set([]);
        rowsCustomCat.set([{ id: 0, filled: false, parentId: '', elementId: '', newPar: false, newEl: false }]);
        entryFormCategorySearchQuery.set('')
        dispatch('cancel');
    }

    function validateURL(url: string) {
        const urlPattern = /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?$/;
        return urlPattern.test(url);
    }

    function preventClear(event: Event) {
        const input = event.target as HTMLInputElement;
        if (!input.value) {
            timestamp = new Date().toISOString().split("T")[0]; // Restore to the default value
        } else {
            timestamp = input.value; // Update the timestamp
        }
    }

    entryFormSelectedCategoriesIds.set($selectedCategories);
    entryFormSelectedTagsIds.set($selectedTags);
    entryFormSelectedStatusesIds.set($selectedStatuses);
    rowsCustomCat.set((() => {
        if ($selectedCustomCategories.length == 0) {
            return [{ id: 0, filled: false, parentId: '', elementId: '', newPar: false, newEl: false}];
        } else {
            const temp:{id: number; filled: boolean; parentId: string; elementId: string, newPar: boolean, newEl: boolean }[] = [];
            let tempId = 0;
            $selectedCustomCategories.forEach(([elementId, parentId]) => {
                temp.push({ id: tempId, filled: true, parentId: parentId, elementId: elementId, newPar: false, newEl: false })
                tempId++;
            });
            return [...temp, { id: tempId, filled: false, parentId: '', elementId: '', newPar: false, newEl: false }];
        }
    })());

    // content and category errors
    $: showContentErrorMessage = hasContentBeenTouched && contentType === 'Note' && content.trim() === '';
    $: showCategoryErrorMessage = hasCategoryBeenTouched && $entryFormSelectedCategoriesIds.length === 0;

    // url error and debounce
    $: {
        isURLValid = validateURL(url);
        showURLErrorMessage = hasURLBeenTouched && !isURLValid && contentType === 'Bookmark';

        // Debounce
        if (contentType==="Bookmark"){
            if (isURLValid) {
                debouncedFetch(url);
            }
        }
    }

    // Empty the fields if the contentType changes
    $: if (contentType !== previousContentType) {
        previousContentType = contentType;

        // Reset everything
        resetFields();
    }


</script>

<div class="modal-backdrop">
    <div class="modal show d-block" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modal-bg" data-testid="add-entry-form">
                <div class="modal-body">

                    <div class="d-flex justify-content-between align-items-center">
                        <div class="text-center">
                            <div class="btn-group btn-group-sm" role="group" aria-label="Entry Type">
                                <input type="radio" class="btn-check" name="entryType" value="Note" id="note" bind:group={contentType}>
                                <label class="btn btn-outline-primary btn-sm" for="note" data-testid="add-entry-form-note">Note</label>

                                <input type="radio" class="btn-check" name="entryType" value="Bookmark" id="bookmark" bind:group={contentType}>
                                <label class="btn btn-outline-primary btn-sm" for="bookmark" data-testid="add-entry-form-bookmark">Bookmark</label>
                            </div>
                        </div>

                        <!-- Date Picker for timestamp -->
                        <div class="form-group">
                            <input
                                    id="date"
                                    type="date"
                                    class="form-control"
                                    bind:value={timestamp}
                                    on:blur={preventClear}
                            required
                            data-testid="add-entry-date"
                            />
                        </div>
                    </div>
                    {#if contentType === 'Note'}
                        <div class="form-group form-inline d-flex pt-4">
                            <input
                                    id="title"
                                    type="text"
                                    class="form-control"
                                    placeholder="Title"
                                    bind:value={title}
                                    required
                            />
                        </div>
                        <div class="form-group mt-2">
                            <textarea
                                    id="content"
                                    class="form-control {showContentErrorMessage ? 'error-border' : ''}"
                                    style="resize: none;"
                                    bind:value={content}
                                    required rows="4"
                                    on:blur={handleContentInput}
                                    on:input={handleContentInput}
                                    data-testid="add-entry-body"
                            ></textarea>
                            {#if showContentErrorMessage}
                                <span style="color: red; margin-top: 4px;">Content cannot be empty</span>
                            {/if}
                        </div>
                    {:else if contentType === 'Bookmark'}
                        <div class="form-group form-inline d-flex flex-column pt-4">
                            <input
                                    id="url"
                                    type="text"
                                    class="form-control {showURLErrorMessage ? 'error-border' : ''}"
                                    placeholder="URL"
                                    on:blur={handleURLInput}
                                    on:input={handleURLInput}
                                    bind:value={url}
                                    required
                            />
                            {#if showURLErrorMessage}
                                <span style="color: red; margin-top: 4px;">Invalid URL</span>
                            {/if}
                        </div>
                        <div class="form-group form-inline d-flex flex-column mt-2">
                            <input id="title" type="text" class="form-control" placeholder="Title" bind:value={title} required/>
                        </div>
                        <div class="form-group form-inline d-flex flex-column mt-2">
                            <textarea id="content" class="form-control" style="resize: none;" bind:value={content} required rows="2" data-testid="add-entry-body"></textarea>
                        </div>
                    {/if}

                </div>

                <EntryFormCateorySearch
                        {showCategoryErrorMessage}
                        bind:hasCategoryBeenTouched
                />
                <EntryFormTagStatusSearch/>
                <EntryFormStatusSearch/>
                <EntryFormAtributeSearch/>

                <div class="modal-footer">
                    <button class="btn btn-secondary" on:click={cancelForm}>Cancel</button>
                    <button class="btn btn-primary" on:click={submitForm}>Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>

<style>
    .modal-content {
        background-color: #e0e6ef;
        padding: 20px;
        border-radius: 5px;
        max-width: 1500px;
        max-height: 80vh;
        overflow: auto;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }

    .error-border {
        border: 1px solid red !important;
    }

    .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
    }

    .btn-group {
        min-width: 200px;
    }

    .btn-group .btn {
        width: 50%;
    }
</style>
