export async function addCategory(newCategoryName: string, parentId: string) {
    const response = await fetch(`${import.meta.env.VITE_LINK as string}/categories`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({name: newCategoryName, parentId: parentId})
    });

    if (response.ok) {
        return response.json();
    } else {
        throw new Error('Failed to add category');
    }
}