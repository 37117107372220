<script lang="ts">
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import { validatePassword } from "./services/validationService";
    import { handleChangePassword } from "./services/userService.js"; // Import your userService method

    let password = '';
    let confirmPassword = '';
    let showPassword = false;
    let showConfirmPassword = false;
    let success = false;
    let errorMessage = '';
    let token = '';

    // Extract the token from the URL
    onMount(() => {
        const url = new URL(window.location.href);
        token = url.pathname.split("/").pop() || "";
    });

    function togglePasswordVisibility() {
        showPassword = !showPassword;
    }

    function toggleConfirmPasswordVisibility() {
        showConfirmPassword = !showConfirmPassword;
    }

    async function onFormSubmit(event: SubmitEvent) {
        event.preventDefault();
        errorMessage = '';

        if (password !== confirmPassword) {
            errorMessage = "Passwords do not match.";
            return;
        }

        if (!validatePassword(password)) {
            errorMessage = "Password must include at least 8 characters, 1 number, and 1 special character.";
            return;
        }

        try {
            const result = await handleChangePassword(token, password, confirmPassword);

            if (result.success) {
                success = true;
                setTimeout(() => navigate("/login"), 3000);
            } else {
                errorMessage = result.message || "Failed to change the password. Please try again.";
            }
        } catch (error) {
            errorMessage = "Unable to connect to the server. Please try again later.";
            console.error(error);
        }
    }

    function cancelReset() {
        navigate('/login');
    }
</script>

<div class="create-user-container">
    <div class="create-user-card">
        <h2 class="text-center mb-4">Change Password</h2>
        {#if success}
            <p class="success-message">Password changed successfully!</p>
            <p class="success-message">Redirecting to login...</p>
        {:else}
            <form on:submit|preventDefault={onFormSubmit}>
                <div class="mb-3 password-field">
                    <label for="password" class="form-label">Password</label>
                    <div class="input-group">
                        {#if showPassword}
                            <input
                                    type="text"
                                    class="form-control"
                                    id="password"
                                    bind:value={password}
                                    placeholder="Enter your password"
                                    required
                            />
                        {:else}
                            <input
                                    type="password"
                                    class="form-control"
                                    id="password"
                                    bind:value={password}
                                    placeholder="Enter your password"
                                    required
                            />
                        {/if}
                        <button class="input-group-text" type="button" on:click={togglePasswordVisibility}>
                            <svg width="24" height="24">
                                <use href={showPassword ? "#eye-open" : "#eye-closed"} />
                            </svg>
                        </button>
                    </div>
                </div>

                <div class="mb-3 password-field">
                    <label for="confirmPassword" class="form-label">Confirm Password</label>
                    <div class="input-group">
                        {#if showConfirmPassword}
                            <input
                                    type="text"
                                    class="form-control"
                                    id="confirmPassword"
                                    bind:value={confirmPassword}
                                    placeholder="Confirm your password"
                                    required
                            />
                        {:else}
                            <input
                                    type="password"
                                    class="form-control"
                                    id="confirmPassword"
                                    bind:value={confirmPassword}
                                    placeholder="Confirm your password"
                                    required
                            />
                        {/if}
                        <button class="input-group-text" type="button" on:click={toggleConfirmPasswordVisibility}>
                            <svg width="24" height="24">
                                <use href={showConfirmPassword ? "#eye-open" : "#eye-closed"} />
                            </svg>
                        </button>
                    </div>
                </div>

                {#if errorMessage}
                    <p class="error-message">{errorMessage}</p>
                {/if}

                <button type="submit" class="btn btn-primary create-user-btn">Change Password</button>
            </form>

            <button class="text-link" on:click={cancelReset}>Cancel</button>
        {/if}
    </div>
</div>

<style>
    .create-user-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background-color: #1a1a1a;
    }

    .create-user-card {
        background-color: #2b2b2b;
        color: #fff;
        padding: 2rem;
        border-radius: 8px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
        width: 400px;
    }

    .create-user-btn {
        background-color: #007bff;
        border: none;
        width: 100%;
    }

    .create-user-btn:hover {
        background-color: #0056b3;
    }

    .text-link {
        all: unset;
        color: #007bff;
        cursor: pointer;
        display: block;
        text-align: center;
        margin-top: 1rem;
    }

    .text-link:hover {
        color: #0056b3;
    }

    .input-group {
        display: flex;
    }

    .input-group-text {
        all: unset;
        background-color: #ffffff;
        color: #000;
        border: 1px solid #ccc;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0 10px;
        border-left: none;
    }

    .input-group input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group-text svg {
        fill: currentColor;
    }

    .form-control {
        background-color: #ffffff;
        border: 1px solid #ccc;
        color: #000;
    }
</style>