<script lang="ts">
    import {categories, type Category, categoryTree, entries, selectedCategories} from "../store";
    import CategoryTree from './CategoryTree.svelte';
    import {addCategory} from "./services/addCategoryService";
    import {fetchCategories} from "./services/fetchCategoriesService";
    import {buildCategoryTree} from "./services/buildCategoryTreeService";
    import {fetchNotes} from "./services/fetchNoteService";

    export let category: Category;
    let isAdding = false;
    let newCategoryName = '';
    let editingCategoryId = '';
    let inputRef: HTMLInputElement | null = null;

    function toggleSelectCategory(categoryId: string) {
        selectedCategories.update(currentCategories => {
            // If the category is already selected, remove it
            if (currentCategories.includes(categoryId)) {
                return currentCategories.filter(selected => selected !== categoryId);
            } else {
                // Else we add it to the selected list
                return [...currentCategories, categoryId];
            }
        });
    }

    async function handleAddCategory(event: Event, newCategoryName:string, parentId: string) {
        event.preventDefault();
        try {
            await addCategory(newCategoryName, parentId);
            newCategoryName = '';
            isAdding = false;
            await fetchCategories();
            categoryTree.set(buildCategoryTree($categories));
        } catch (error) {
            console.error(error);
        }
    }

    function editCategory(catId: string, name: string) {
        if (editingCategoryId === '') {
            editingCategoryId = catId;
            newCategoryName = name;
        }
    }

    async function deleteCategory(categoryId: string) {
        try {
            const response = await fetch(`${import.meta.env.VITE_LINK as string}/categories/${categoryId}`, {
                method: 'DELETE',
            });
            await fetchCategories();
            categoryTree.set(buildCategoryTree($categories));
            $entries = await fetchNotes();
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    }

    function handleAddNewChildCategoryButton() {
        isAdding = !isAdding;
        newCategoryName = '';
    }

    async function editCategoryById(categoryId: string, name:string, oldName: string) {
        try {
            if (name !== oldName) {
                const response = await fetch(`${import.meta.env.VITE_LINK as string}/categories/${categoryId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ name })
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message);
                }

                await fetchCategories();
                categoryTree.set(buildCategoryTree($categories));
                $entries = await fetchNotes();
            }
            newCategoryName = '';
            editingCategoryId = '';
        } catch (error) {
            console.error(error);
        }
    }

    function getCategory(child: string) {
        return $categories.get(child) as Category;
    }

    $: if (editingCategoryId !== '' && inputRef) {
        inputRef.focus();
    }

</script>

<ul class="list-unstyled">
    <li class="list-item ">
        <div class="element {$selectedCategories.includes(category._id) ? 'selected' : ''}">
            <div>
                {#if editingCategoryId === category._id}
                    <input
                            type="text"
                            bind:value={newCategoryName}
                            bind:this={inputRef}
                            class="editable"
                            on:blur={() => editCategoryById(category._id, newCategoryName, category.name)}
                            on:keyup={(e) => {
                                if (e.key === 'Enter') {
                                    editingCategoryId = '';
                                }
                            }}
                    />
                {:else}
                    <div class="d-flex justify-content-between">
                        <button class="textelement" on:click={() => toggleSelectCategory(category._id)}>
                            {category.name}
                        </button>
                        <div class="action-buttons d-flex justify-content-between">
                            <button class="category_buttons" on:click|stopPropagation={() => handleAddNewChildCategoryButton()}>
                                <svg width="16" height="16">
                                    <use href="#icon-add"></use>
                                </svg>
                            </button>
                            <button class="category_buttons" on:click|stopPropagation={() => editCategory(category._id, category.name)}>
                                <svg width="16" height="16">
                                    <use href="#icon-edit-simple"></use>
                                </svg>
                            </button>
                            <button class="category_buttons" on:click|stopPropagation={() => deleteCategory(category._id)}>
                                <svg width="16" height="16">
                                    <use href="#icon-trash"></use>
                                </svg>
                            </button>
                        </div>
                    </div>
                {/if}
            </div>

        </div>
        {#if isAdding}
            <form on:submit={(event) => handleAddCategory(event, newCategoryName, category._id)}>
                <input bind:value={newCategoryName} placeholder="Category name" required/>
                <button type="submit" class="btn btn-primary">Save</button>
                <button type="button" class="btn btn-secondary"
                        on:click={() => { isAdding = false; newCategoryName = ''; }}>Cancel
                </button>
            </form>
        {/if}

        {#if category.children.length > 0}
            <ul>
                {#each category.children as child}
                    <CategoryTree category={getCategory(child)}/>
                {/each}
            </ul>
        {/if}
    </li>
</ul>

<style>
    .list-item {
        cursor: pointer;
        background-color: #212529;
        transition: background-color 0.2s ease-in-out;
        width: 100%;
        box-sizing: border-box;
        text-align: left;
        padding-top: 10px;
        border-radius: 3px;
    }

    .element:hover {
        background-color: #1c2026;
    }

    .selected {
        background-color: #292f38;
    }

    .category_buttons {
        color: white;
    }

    .action-buttons {
        display: inline-flex;
        margin-left: auto; /* Push icons to the right side */
    }

    .action-buttons button {
        display: inline-block;
        background: none;
        border: none;
        color: #fff; /* Icon color */
        font-size: 16px;
        margin-right: 8px;
        cursor: pointer;
    }

    .action-buttons button:hover {
        color: #fff; /* Highlight icon on hover */
    }

    /* Hide action buttons by default */
    .action-buttons {
        visibility: hidden;
    }

    /* Show buttons on hover */
    .element:hover .action-buttons {
        visibility: visible;
    }

    .list-item .element {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .textelement {
        all: unset;
        padding-right: 20px;
    }
</style>