<script lang="ts">
    import {
        categories,
        type Category, entryFormCategorySearchDropdownOpen, entryFormCategorySearchInputElement,
        entryFormCategorySearchMatchIds, entryFormCategorySearchQuery,
        entryFormSelectedCategoriesIds,
    } from "../store";
    import EntryFormCategorySearchComponent from "./EntryFormCategorySearchComponent.svelte";

    export let category: Category;

    function getCategory(child: string) {
        return $categories.get(child) as Category;
    }
</script>

<ul class="list list-unstyled">
    <li>
        <button class="dropdown-item" disabled={!$entryFormCategorySearchMatchIds.has(category._id) || $entryFormSelectedCategoriesIds.includes(category._id)}
                on:click|stopPropagation={() => {
                    entryFormCategorySearchDropdownOpen.set(false);
                    entryFormCategorySearchQuery.set('');
                    entryFormSelectedCategoriesIds.update(items => {
                        return [...items, category._id];
                    });
                    $entryFormCategorySearchInputElement.blur();
                }}>
            {category.name}
        </button>
        {#if category.children.length > 0}
            <div>
                {#each category.children as child}
                    <EntryFormCategorySearchComponent category={getCategory(child)}/>
                {/each}
            </div>
        {/if}
    </li>
</ul>

<style>
    .list {
        padding-left: 1rem;
    }

    .dropdown-item {
        all: unset;
        cursor: pointer;
    }

    .dropdown-item:disabled{
        color: grey;
        cursor: unset;
    }
</style>