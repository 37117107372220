import {type Category} from "../../store";

export function buildCategoryTree(categories: Map<string, Category>) {
    let rootCategories: Category[] = [];
    categories.forEach((value, key) => {
        if (value.parentId === "") {
            rootCategories.push(value);
        } else {
            if (categories.get(value.parentId)) {
                categories.get(value.parentId)!.children.push(key);
            }
        }
    });
    return rootCategories;
}