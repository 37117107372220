<script lang="ts">
    import TagsBar from "./TagsBar.svelte";
    import StatusBar from "./StatusBar.svelte";
    import {handleLogout} from "./services/logoutHelper";
    import {onMount} from "svelte";
    import {uiTimebarHeight, uiTopSectionHeight} from "../store";

    let topSection: HTMLElement;


    $: if (topSection) {
        uiTopSectionHeight.set(topSection.clientHeight);
    }

    onMount(() => {
        if (topSection) {
            uiTopSectionHeight.set(topSection.clientHeight);
        }
    })
</script>

<div class="top-bar d-flex justify-content-between align-items-center" bind:this={topSection}>
    <div class="logo text-white">
        Timeline
    </div>

    <TagsBar />
    <StatusBar />

    <div class="logout text-white">
        <button class="btn btn-danger" on:click={handleLogout}>Log Out</button>
    </div>
</div>

<style>
    .top-bar {
        background-color: #1c1e21;
        padding: 0.5rem 1.5rem;
        color: white;
        display: flex;
        justify-content: space-between;
    }

    .logo {
        font-size: 32px;
        background-color: #1c1e21;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    }

    .logout {
        font-size: 16px;
        background-color: #1c1e21;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
        text-align: right;
    }
</style>