import {navigate} from "svelte-routing";

export async function handleCreateUser(username: string, email: string, password: string): Promise<void> {
    try {
        const response = await fetch(`${import.meta.env.VITE_LINK}/users`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({ username, email, password }),
        });

        if (response.ok) {
            navigate(`/confirmation-pending?email=${email}`);
        } else {
            const errorData = await response.json();
            console.error("User creation failed:", errorData.message);
            alert("User creation failed: " + (errorData.message || "Please try again later."));
        }
    } catch (error) {
        console.error("Error during user creation:", error);
        alert("An error occurred during user creation. Please try again later.");
    }
}

export async function handleAccountConfirmation(token: string): Promise<{ success: boolean; message?: string }> {
    try {
        const response = await fetch(`${import.meta.env.VITE_LINK}/confirm/${token}`, {
            method: "GET",
        });

        if (response.ok) {
            return { success: true, message: "Account confirmed successfully! You can now log in." };
        } else {
            const result = await response.json();
            return { success: false, message: result.message || "Invalid or expired confirmation token." };
        }
    } catch (error) {
        console.error("Error confirming account:", error);
        return { success: false, message: "Unable to connect to the server. Please try again later." };
    }
}

export async function resendValidationEmail(email: string): Promise<{ success: boolean; message: string }> {
    try {
        const response = await fetch(`${import.meta.env.VITE_LINK}/resend-confirmation`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email }),
        });

        if (response.ok) {
            const result = await response.json();
            return { success: true, message: result.message };
        } else {
            const error = await response.json();
            return { success: false, message: error.message || 'Failed to resend confirmation email.' };
        }
    } catch (error) {
        console.error('Error resending confirmation email:', error);
        return { success: false, message: 'An error occurred while resending the email.' };
    }
}


export async function handleChangePassword(token: string, password: string, confirmPassword: string): Promise<{ success: boolean; message?: string }> {
    try {
        const response = await fetch(`${import.meta.env.VITE_LINK}/reset-password/${token}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ password, confirmPassword }),
        });

        if (response.ok) {
            return { success: true };
        } else {
            const result = await response.json();
            return { success: false, message: result.message || "Failed to change the password. Please try again." };
        }
    } catch (error) {
        console.error("Error changing password:", error);
        return { success: false, message: "Unable to connect to the server. Please try again later." };
    }
}