<script lang="ts">
    import { navigate  } from "svelte-routing";
    import { onMount } from "svelte";
    import { handleAccountConfirmation } from "./services/userService";

    export let token: string;
    let validationMessage: string | undefined = "";
    let isValidating = true;

    onMount(async () => {
        const url = new URL(window.location.href);
        token = url.pathname.split("/").pop() || ""; // Extract the token
        const result = await handleAccountConfirmation(token);
        validationMessage = result.message;
        if (result.success) {

           setTimeout(() => navigate('/login'), 3000); // Redirect after 3 seconds
        }
        isValidating = false;
    });
</script>

<div class="validation-container">
    {#if isValidating}
        <p>Validating your account...</p>
    {:else}
        <p class="validation-message">{validationMessage}</p>
    {/if}
</div>

<style>
    .validation-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background-color: #1a1a1a;
        color: #fff;
        text-align: center;
        flex-direction: column;
    }

    .validation-message {
        font-size: 1.2rem;
        margin-top: 1rem;
    }
</style>
