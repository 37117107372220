<script lang="ts">
    import {onDestroy, onMount} from "svelte";
    import {
        categories,
        type Category, entryFormCategorySearchDropdownOpen, entryFormCategorySearchInputElement,
        entryFormCategorySearchMatchIds, entryFormCategorySearchQuery,
        entryFormSelectedCategoriesIds,
    } from "../store";
    import EntryFormCategorySearchComponent from "./EntryFormCategorySearchComponent.svelte";
    import {buildCategoryTree} from "./services/buildCategoryTreeService";

    let container: HTMLElement;
    let validElements: Map<string, Category> = new Map();
    let catTree: Category[];
    let selectedElements: Category[];

    export let showCategoryErrorMessage = false;
    export let hasCategoryBeenTouched = false;


    $: {
        const matchingIds = new Set<string>();
        $categories.forEach((category, key) => {
            if ($entryFormCategorySearchQuery !== '') {
                if (category.name.toLowerCase().includes($entryFormCategorySearchQuery.toLowerCase()) &&
                    !$entryFormSelectedCategoriesIds.includes(key)) {
                    matchingIds.add(key);
                }
            } else {
                matchingIds.add(key);
            }
        });
        entryFormCategorySearchMatchIds.set(matchingIds);
    }

    $: validElements = (() => {
        const resultSet: Map<string, Category> = new Map();
        $entryFormCategorySearchMatchIds.forEach(id => {
            let current = $categories.get(id);
            resultSet.set(id, current!);
            while (current!.parentId) {
                current = $categories.get(current!.parentId);
                current!.children = [];
                resultSet.set(current!._id, current!);
            }
        });
        return resultSet;
    })();

    $: catTree = buildCategoryTree(validElements);

    $: selectedElements = $entryFormSelectedCategoriesIds.map(id => $categories.get(id) as Category);

    $: {
        if ($entryFormSelectedCategoriesIds.length !== 0){
            hasCategoryBeenTouched = true;
        }
    }

    function handleCategoryInteraction() {
        hasCategoryBeenTouched = true;
    }

    function removeSelectedElement(elementId: string) {
        entryFormSelectedCategoriesIds.set($entryFormSelectedCategoriesIds.filter(element => element !== elementId));
    }

    function removeAllSelectedElements() {
        entryFormSelectedCategoriesIds.set([]);
    }

    function handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            if ($entryFormCategorySearchMatchIds.size == 1) {
                entryFormSelectedCategoriesIds.update(items => {
                    return [...items, $entryFormCategorySearchMatchIds.values().next().value];
                })
                entryFormCategorySearchQuery.set('');
                entryFormCategorySearchDropdownOpen.set(false);
                $entryFormCategorySearchInputElement.blur();
            } else if ($entryFormCategorySearchMatchIds.size > 1) {
                const temp = Array.from($entryFormCategorySearchMatchIds)
                    .map((key) => $categories.get(key) as Category)
                    .filter(item => item.name.toLowerCase() === $entryFormCategorySearchQuery.toLowerCase());

                if (temp.length === 1) {
                    entryFormSelectedCategoriesIds.update(items => {
                        return [...items, temp[0]._id];
                    })
                }
                entryFormCategorySearchQuery.set('');
                entryFormCategorySearchDropdownOpen.set(false);
                $entryFormCategorySearchInputElement.blur();
            }
        }
    }

    const handleOutsideClick = (event: MouseEvent) => {
        if (container && !container.contains(event.target as Node)) {
            if ($entryFormCategorySearchDropdownOpen){
                handleCategoryInteraction();
            }
            entryFormCategorySearchDropdownOpen.set(false);
        }
    };

    onMount(() => {
        document.addEventListener('click', handleOutsideClick);
    });

    onDestroy(() => {
        document.removeEventListener('click', handleOutsideClick);
    });
</script>


<div>
    <div class="container" bind:this={container}>
        <input
                type="text"
                class="form-control {showCategoryErrorMessage ? 'error-border' : ''}"
                placeholder="Categories"
                bind:value={$entryFormCategorySearchQuery}
                bind:this={$entryFormCategorySearchInputElement}
                on:keydown={handleKeyDown}
                on:focus={() => {
                    entryFormCategorySearchDropdownOpen.set(true);
                    hasCategoryBeenTouched = false;
                }}
        />
        {#if showCategoryErrorMessage}
            <span style="color: red; margin-bottom: 4px;">At least one category must be selected</span>
        {/if}
        {#if $entryFormCategorySearchDropdownOpen && validElements.size > 0}
            <div class="custom-dropdown-menu d-flex flex-column align-items-start" style={`width: ${$entryFormCategorySearchInputElement.clientWidth}px;`}>
                {#each catTree as root}
                    <EntryFormCategorySearchComponent category={root}/>
                {/each}
            </div>
        {/if}
    </div>

    <div>
        {#if selectedElements.length > 0}
            <div class="selected d-inline-flex align-items-center rounded flex-wrap">
                {#each selectedElements as element}
                    <button class="chip rounded d-flex align-items-center text-white fw-bold"
                            on:click={() => removeSelectedElement(element._id)}>
                        {element.name}
                        <span class="close">&times;</span>
                    </button>
                {/each}
                <button class="close" on:click|stopPropagation={() => removeAllSelectedElements()}>&times;</button>
            </div>
        {/if}
    </div>
</div>


<style>
    .error-border {
        border: 1px solid red !important;
    }

    .container {
        padding-left: 1rem;
        padding-bottom: 0.25rem;
    }

    .custom-dropdown-menu {
        position: absolute;
        margin-top: 1px;
        background-color: #a5a8b6;
        border-radius: 2px;
        padding: 10px;
        list-style: none;
        z-index: 1001;
        max-height: 25vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .selected {
        background-color: #d4dae5;
        margin-bottom: 0.5rem;
        padding: 0.15rem 0.25rem;
        margin-left: 1rem;
    }
    .chip {
        all: unset;
        margin-left: 0.25rem;
        height: 2rem;
        background-color: #6d7583;
        padding: 0.15rem 0.5rem;
    }

    .close {
        all: unset;
        cursor: pointer;
        text-align: center;
        margin-left: 5px;
    }
</style>