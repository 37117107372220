import {selectedEntry, Entry} from "../../store";

export async function fetchMetadata(url) {
    try {
        const response = await fetch(`${import.meta.env.VITE_LINK}/api/preview?url=${encodeURIComponent(url)}`);
        if (!response.ok) {
            console.warn(`Failed to fetch metadata for URL: ${url}`);
            return null;
        }

        const data = await response.json();
        return {
            title: data.title || '',
            description: data.description || '',
            image: data.image || '',
            url: data.url || ''
        };
    } catch (error) {
        console.error(`Error fetching metadata for URL: ${url}`, error);
        return null;
    }
}

export async function handleMetadataUpdate(url: string, entryId: string,) {
    try {
        // Fetch metadata
        const metadata = await fetchMetadata(url);
        if (!metadata) {
            return;
        }

        // Update the note
        const response = await fetch(`${import.meta.env.VITE_LINK as string}/notes/${entryId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                metadata: metadata
            })
        });

        if (!response.ok) {
            console.error('Failed to update metadata:', await response.json());
        } else {
            const updatedNote = await response.json();
            console.log('Metadata updated successfully:', updatedNote);

            return metadata;
        }
    } catch (error) {
        console.error('Error updating metadata:', error);
    }
}