import {categories, type Category} from "../../store";

export async function fetchCategories() {
    try {
        const response = await fetch(`${import.meta.env.VITE_LINK as string}/categories`);
        if (response.ok) {
            const categoryRes = await response.json();
            const catList: Category[] = await Promise.all(
                categoryRes.map(async (category: any) => {
                    return {
                        _id: category._id,
                        name: category.name,
                        parentId: category.parentId,
                        children: []
                    };
                })
            );
            categories.set(new Map(catList.map(cat => [cat._id, cat])));
        } else {
            console.error("Failed to fetch categories");
        }
    } catch (error) {
        console.error("Error fetching categories:", error);
    }
}