<script lang="ts">
    import {createEventDispatcher, onDestroy, onMount} from "svelte";

    const dispatch = createEventDispatcher();

    let inputElement: HTMLElement;
    let mainElement: HTMLElement;
    let inputValue = '';

    function handleSubmit() {
        if (inputValue !== '') {
            dispatch('submit', {value: inputValue});
        } else {
            dispatch('cancel');
        }
    }

    function handleCancel() {
        dispatch('cancel');
    }

    function handleMouseDown(event: MouseEvent) {
        if (mainElement && !mainElement.contains(event.target as Node)) {
            handleSubmit();
        }
    }

    function handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            handleSubmit();
        } else if (event.key === 'Escape') {
            dispatch('cancel');
        }
    }

    $: if (inputElement) {
        inputElement.focus();
    }

    onMount(() => {
        window.addEventListener('mousedown', handleMouseDown);
        window.addEventListener('keydown', handleKeyDown);
    });

    onDestroy(() => {
        window.removeEventListener('mousedown', handleMouseDown);
        window.removeEventListener('keydown', handleKeyDown);
    });
</script>

<div class="main d-flex" bind:this={mainElement}>
    <input
            type="text"
            bind:value={inputValue}
            bind:this={inputElement}
    />
    <button class="buttons" on:click|stopPropagation={handleSubmit}>
        <svg width="15" height="15">
            <use href="#check-mark"/>
        </svg>
    </button>
    <button class="buttons" on:click|stopPropagation={handleCancel}>
        <svg width="15" height="15">
            <use href="#icon-close"/>
        </svg>
    </button>
</div>

<style>
    .main {
        padding-left: 1rem;
    }

    .buttons {
        background: none;
        border: none;
        font-size: 0.75rem;
        cursor: pointer;
        transition: transform 0.05s ease-in-out;
        color: white;
    }

    .buttons:hover {
        transform: scale(1.1);
    }
</style>