<script lang="ts">
    import {
        entryFormSelectedTagsIds, newTagsFromEntryFormTagSearch,
        type Tag,
        tags
    } from "../store";
    import {onDestroy, onMount} from "svelte";

    let isDropdownOpen = false;
    let searchQuery = '';
    let container: HTMLElement;
    let inputElement: HTMLElement;
    let validElements: Tag[] = [];
    let selectedElements: Tag[] = [];
    let newTags: string[] = [];
    newTagsFromEntryFormTagSearch.set([]);

    const handleOutsideClick = (event: MouseEvent) => {
        if (container && !container.contains(event.target as Node)) {
            isDropdownOpen = false;
        }
    };

    $: validElements = (() => {
        const valid: Tag[] = [];
        $tags.forEach((tag, key) => {
            if (!$entryFormSelectedTagsIds.includes(key)) {
                if (tag.name.toLowerCase().includes(searchQuery.toLowerCase())) {
                    valid.push(tag);
                }
            }
        });
        return valid;
    })();

    $: selectedElements = $entryFormSelectedTagsIds.map(id => $tags.get(id) as Tag)

    function handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            if (validElements.length === 0) {
                if (searchQuery !== '') {
                    if (!$newTagsFromEntryFormTagSearch.some((item) => item.toLowerCase() === searchQuery.toLowerCase())) {
                        newTagsFromEntryFormTagSearch.set([...$newTagsFromEntryFormTagSearch, searchQuery])
                    }
                    searchQuery = '';
                    isDropdownOpen = false;
                }
            } else {
                let item = validElements.find(element => element.name.toLowerCase() === searchQuery.toLowerCase());
                if (item) {
                    searchQuery = '';
                    entryFormSelectedTagsIds.update(items => {
                        return [...items, item!._id];
                    });
                    isDropdownOpen = false;
                } else {
                    if (searchQuery !== '') {
                        if (!$newTagsFromEntryFormTagSearch.some((item) => item.toLowerCase() === searchQuery.toLowerCase())) {
                            newTagsFromEntryFormTagSearch.set([...$newTagsFromEntryFormTagSearch, searchQuery])
                        }
                        searchQuery = '';
                        isDropdownOpen = false;
                    }
                }
            }
        }
    }

    function handleMouseDown(event: MouseEvent) {
        if (isDropdownOpen) {
            if (container && !container.contains(event.target as Node)) {
                if (searchQuery !== '') {
                    if (validElements.length === 0) {
                        if (!$newTagsFromEntryFormTagSearch.some((item) => item.toLowerCase() === searchQuery.toLowerCase())) {
                            newTagsFromEntryFormTagSearch.set([...$newTagsFromEntryFormTagSearch, searchQuery])
                        }
                        searchQuery = '';
                        isDropdownOpen = false;
                    } else {
                        let item = validElements.find(element => element.name.toLowerCase() === searchQuery.toLowerCase());
                        if (item) {
                            searchQuery = '';
                            entryFormSelectedTagsIds.update(items => {
                                return [...items, item!._id];
                            });
                            isDropdownOpen = false;
                        } else {
                            if (!$newTagsFromEntryFormTagSearch.some((item) => item.toLowerCase() === searchQuery.toLowerCase())) {
                                newTagsFromEntryFormTagSearch.set([...$newTagsFromEntryFormTagSearch, searchQuery])
                            }
                            searchQuery = '';
                            isDropdownOpen = false;
                        }
                    }
                }
            }
        }
    }

    $: if ($newTagsFromEntryFormTagSearch) {
        newTags = [...$newTagsFromEntryFormTagSearch];
    }

    $: if (searchQuery !== '') {
        isDropdownOpen = true;
    }

    function removeSelectedElement(elementId: string) {
        entryFormSelectedTagsIds.set($entryFormSelectedTagsIds.filter(element => element !== elementId))
    }

    function removeSelectedNewElement(name: string) {
        newTagsFromEntryFormTagSearch.set($newTagsFromEntryFormTagSearch.filter(element => element !== name));
    }

    function removeAllSelectedElements() {
        entryFormSelectedTagsIds.set([]);
        newTagsFromEntryFormTagSearch.set([]);
    }

    onMount(() => {
        document.addEventListener('click', handleOutsideClick);
        window.addEventListener('mousedown', handleMouseDown);
    });

    onDestroy(() => {
        document.removeEventListener('click', handleOutsideClick);
        window.removeEventListener('mousedown', handleMouseDown);
    });
</script>

<div>
    <div class="container" bind:this={container}>
        <input
                type="text"
                class="form-control"
                placeholder={"Tags"}
                bind:value={searchQuery}
                bind:this={inputElement}
                on:keydown={handleKeyDown}
                on:focus={() => {isDropdownOpen = true}}
                data-testid="form-tags-selector"
        />
        {#if isDropdownOpen && validElements.length > 0}
            <div class="custom-dropdown-menu d-flex flex-column align-items-start" style={`width: ${inputElement.clientWidth}px;`}>
                {#each validElements as element}
                    <button class="custom-button" on:click|stopPropagation={() => {
                    isDropdownOpen = false;
                    entryFormSelectedTagsIds.update(items => {
                        return [...items, element._id];
                    });
                }}>
                        {element.name}
                    </button>
                {/each}
            </div>
        {/if}
    </div>
    <div>
        {#if selectedElements.length > 0 || newTags.length > 0}
            <div class="selected d-inline-flex align-items-center rounded flex-wrap">
                {#each selectedElements as element}
                    <button class="chip rounded d-flex align-items-center text-white fw-bold"
                            on:click={() => removeSelectedElement(element._id)}>
                        {element.name}
                        <span class="close">&times;</span>
                    </button>
                {/each}
                {#each newTags as newTag}
                    <button class="chip rounded d-flex align-items-center text-white fw-bold"
                            on:click={() => removeSelectedNewElement(newTag)}>
                        {newTag}
                        <span class="close">&times;</span>
                    </button>
                {/each}
                <button class="close" on:click|stopPropagation={() => removeAllSelectedElements()}>&times;</button>
            </div>
        {/if}
    </div>
</div>

<style>
    .container {
        padding-left: 1rem;
        padding-bottom: 0.25rem;
    }

    .custom-dropdown-menu {
        position: absolute;
        margin-top: 1px;
        background-color: #a5a8b6;
        border-radius: 2px;
        padding: 10px;
        list-style: none;
        z-index: 1001;
        max-height: 25vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .custom-button {
        all: unset;
        cursor: pointer;
        text-align: center;
        padding-left: 0.75rem;
    }

    .selected {
        background-color: #d4dae5;
        margin-bottom: 0.5rem;
        padding: 0.15rem 0.25rem;
        margin-left: 0.5rem;
    }
    .chip {
        all: unset;
        margin-left: 0.25rem;
        height: 2rem;
        background-color: #6d7583;
        padding: 0.15rem 0.5rem;
    }

    .close {
        all: unset;
        cursor: pointer;
        text-align: center;
        margin-left: 5px;
    }
</style>