<script lang="ts">
    import {
        type CustomCategoryElement,
        selectedCustomCategories
    } from "../store";
    import {createEventDispatcher} from "svelte";

    export let parentId: string;
    export let customCategoryElements: CustomCategoryElement[];
    export let height: string;

    const dispatch = createEventDispatcher();

    let editElementId = '';
    let newElementName = '';
    let inputRef: HTMLInputElement;
    let escapePressed = false;

    function editCustomElement(elementId: string, name: string) {
        if (editElementId === '') {
            editElementId = elementId;
            newElementName = name;
        }
    }

    function toggleSelectElement(elementId: string) {
        selectedCustomCategories.update(current => {
            const pair = [elementId, parentId];
            if (current.some(([selectedId, selectedParent]) => selectedId === elementId && selectedParent === parentId)) {
                return current.filter(([selectedId, selectedParent]) => selectedId !== elementId || selectedParent !== parentId);
            } else {
                return [...current, pair];
            }
        });
    }

    function handleBlur(element: CustomCategoryElement) {
        if (!escapePressed) {
            dispatch('edit', {elementId: element._id, parentId: element.parentId, newName: newElementName, oldName: element.name});
            escapePressed = false;
        } else {
            escapePressed = false;
        }
        newElementName = '';
        editElementId = '';
    }

    $: if (editElementId !== '' && inputRef) {
        inputRef.focus();
    }
</script>

<div class="comp" style={height}>
    <ul class="list-unstyled">
        {#each customCategoryElements as element}
            <li class="list-item text-white">
                <div class="element" class:selected={$selectedCustomCategories.some(([selectedId, selectedParent]) =>
    selectedId === element._id && selectedParent === parentId
)}>
                    {#if editElementId === element._id}
                        <input
                                type="text"
                                bind:value={newElementName}
                                bind:this={inputRef}
                                class="editable"
                                on:blur={() => {handleBlur(element)}}
                                on:keydown|stopPropagation={(e) => {
                                    if (e.key === 'Enter') {
                                        inputRef.blur()
                                    }
                                    else if (e.key === 'Escape') {
                                        escapePressed = true;
                                        inputRef.blur();
                                    }
                                }}
                        />
                    {:else}
                        <div class="element d-flex justify-content-between">
                            <button class="textelement" on:click={() => toggleSelectElement(element._id)}>
                                {element.name}
                            </button>
                            <div class="action-buttons d-flex justify-content-between">
                                <button class="category_buttons" on:click|stopPropagation={() => editCustomElement(element._id, element.name)}>
                                    <svg width="16" height="16">
                                        <use href="#icon-edit-simple"/>
                                    </svg>
                                </button>
                                <button class="category_buttons" on:click|stopPropagation={() => dispatch('delete', {elementId: element._id, parentId: element.parentId})}>
                                    <svg width="16" height="16">
                                        <use href="#icon-trash"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    {/if}
                </div>
            </li>
        {/each}
    </ul>
</div>

<style>
    .comp {
        overflow: auto;
    }

    .list-item {
        cursor: pointer;
        background-color: #212529;
        transition: background-color 0.2s ease-in-out;
        width: 100%;
        box-sizing: border-box;
        text-align: left;
        padding-left: 15px;
        padding-top: 10px;
        border-radius: 3px;
    }

    .selected {
        background-color: #292f38;
    }
    .action-buttons {
        display: inline-flex;
        margin-left: auto; /* Push icons to the right side */
    }

    .action-buttons button {
        display: inline-block;
        background: none;
        border: none;
        color: #fff; /* Icon color */
        font-size: 16px;
        margin-right: 8px;
        cursor: pointer;
    }

    .action-buttons button:hover {
        color: #fff; /* Highlight icon on hover */
    }

    /* Hide action buttons by default */
    .action-buttons {
        visibility: hidden;
    }

    /* Show buttons on hover */
    .element:hover .action-buttons {
        visibility: visible;
    }

    .list-item .element {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .textelement {
        all: unset;
        padding-right: 20px;
    }
</style>